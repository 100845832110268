.interactive-references-input {
    display: inline-flex;
    /* text-decoration: underline; */
    cursor: text;
    border-radius: 3px;
    /* text-underline-offset: 3px; */
    /* text-decoration-color: rgb(223, 223, 223); */
}
/* .interactive-references-input:hover {
    background-color: gainsboro;
    color: black;
} */
