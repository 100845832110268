.referenceElement {
    color: black;
    /* text-decoration: underline; */
    text-decoration-thickness: 1px;

    text-underline-offset: 2px;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.12s ease-out, box-shadow 0.12s ease-out;
}

.referenceElement.repeats {
    color: var(--deep-blue);

    /* color: rgb(83, 0, 199); */

    /* color: rgb(0, 0, 159); */

    /* text-decoration: underline; */
    /* text-decoration-offset: 0; */
    text-underline-offset: 0px;

    text-decoration-thickness: 1px;
}
.referenceElement.unconfirmed {
    /* color: var(--deep-blue); */

    /* color: black; */
    /* font-weight: normal; */
}
.referenceElement.repeats:not(.unconfirmed) {
    /* background-color: rgb(238, 245, 255); */
    color: rgb(61, 0, 184);
    /* text-decoration: underline; */
    text-decoration-thickness: 0.15em;
    text-decoration-color: rgb(221, 221, 255);

    /* background-color: rgb(233, 233, 255); */

    /* font-weight: bold; */
    /* font-weight: bold; */
}

.referenceElement.flare,
.referenceElement.clickable:hover {
    color: var(--electric-blue);
    cursor: pointer;
    background-color: var(--background-purple);
    /* box-shadow: 0 0 6px rgb(185, 188, 255); */
    text-decoration: none;
}
.referenceElement.title {
    cursor: default;
}
