.search-pages {
    margin: 0 0em 0.5em;
    padding-left: 0.5em;
    /* width: 96%; */
    display: flex;
    align-self: center;
    /* background-color: rgb(242, 242, 242) !important; */
    /* rgba(247, 247, 247, 0.5) !important; */
    border-radius: 0.5em;
    outline: 2px rgb(233, 233, 233) solid;
    background-color: white;
}
.search-pages > div {
    width: 100%;
    /* that's ^ for search text to text up all of search pages */
}
.search-pages:hover {
    cursor: text;
}
.search-pages:focus-within {
    outline: none;
    /* box-shadow: 0 0 8px gainsboro; */
}
