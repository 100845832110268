.sidebar-link {
    line-height: 24px;
    padding-top: 0px;
    padding-bottom: 0px;
}
.sidebar-link:hover {
    background-color: gainsboro;
}

/* .predicate .concept #xOut{
    
  } */

.selected-component {
    border-color: red;
    border-width: 4px;
    background-color: rgb(255, 225, 225);
}
#plexusLogo:hover {
    /* background-color: rgb(0, 17, 174); */
    /* background-color: rgb(255, 225, 225);
    border-radius: 8px; */
}
#components-title {
    margin: 18px;
}
.sidebar {
    /* display: flex; */

    position: fixed;
    top: 0;
    right: 0px;
    bottom: 0;
    font-family: 'Optima';
    font-size: 24px;
    color: rgb(75, 81, 85);
    background: rgba(255, 255, 255, 0.932);
    transition: transform 0.5s ease 0s;
    z-index: 2;
    min-width: 220px;
    /* overflow-y: auto; */
    /* width: 20%; */
}
.sidebar-in {
    transform: translateX(100%);
}
.sidebar-baby {
    line-height: 24px;
}
.right-sidebar-arrow {
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 100%;
    /* width: 32px; */
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    cursor: default;
    padding-left: 2px;
    padding-right: 2px;
    border-left: 1px solid rgb(236, 238, 240);
    background: rgba(255, 255, 255, 0.932);

    /* border-right: 1px solid rgb(236, 238, 240); */
    /* background: var(--very-light-gray); */
    /* border-left: solid 1px lightgray; */
}
.right-sidebar-arrow:hover {
    background: rgb(255, 219, 219);
    cursor: pointer;
}
.components {
    color: var(--plex-color);
    font-family: var(--the-font-family);
    overflow-y: auto;
}
.component {
    border-top: 1px solid rgb(236, 238, 240);
    border-bottom: 1px solid rgb(236, 238, 240);
    margin: 0px 4px 4px 4px;
    border-radius: 6px;
    font-weight: var(--font-weight);
    font-size: 12px;
}
#components-title {
    /* text-align: center; */
    font-size: 32px;
    text-align: center;
    font-style: italic;
    padding: 0px 0.3em;
}
