#referlistemail {
    color: black;
    /* font-size: 0.8em; */
    /* flex: 3; */
    width: 10em;
    min-width: 80pt;
    border: none;
}
#referlistbutton {
    color: black;
    font-size: 0.7em;
    min-width: 7em;

    /* flex: 2; */
    width: fit-content;
    border: none;
}
