:root {
    --x-padding: 0.2em;
    /* --concept-store-width: 18vw; */
}
#concept-store-container {
    /* width: 0; */
    height: 100%; /* max-width: 260pt; */
    /* width: fit-content; */
    max-width: 300pt;
    min-width: 140pt;
    flex: 1;
    padding: 1em 1em;
    /* padding-top: 4em; */
    display: flex;
    justify-content: top;
    flex-direction: column;
    background-color: rgb(240, 240, 240);
    /* inset: 2px rgb(242, 242, 242) solid; */
    border-radius: 8px;
    /* margin-bottom: 6em; */
}

#concept-store {
    font-size: calc(0.9 * var(--normal-bullet-font-size));
    /* height: 90%; */
    /* padding: 0.2em 0.5em; */
    padding-bottom: 0;
    /* margin: 0.5em; */
    margin-top: 0;
    margin-bottom: 0px;
    /* padding-top: 0.5em; */
    color: gray;
    display: flex;
    flex-direction: column;
    border-radius: 0.4em;
    align-items: stretch;
}
#concept-store:hover {
    cursor: default;
}

.concept-list-in-store {
    max-height: 90vh;
    height: inherit;
    padding: 0 !important;
    overflow: auto;
    opacity: 1;
    transition: opacity 0.5s;
}
.concept-list-in-store::-webkit-scrollbar {
    width: 0.7em;
}

.concept-list-in-store::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
}

.concept-list-in-store::-webkit-scrollbar-thumb {
    /* background-color: rgb(255, 215, 215); */
    background-color: transparent;
    border-radius: 3px;
    /* outline: 1px solid slategrey; */
}
/*  */
/* @media all and (max-width: 500pt) {
    #concept-store-container {
        display: none;
    }
} */
#concept-store-title {
    position: absolute;
    left: 0;
    top: 0;
    font-size: calc(0.9 * var(--normal-bullet-font-size));
    /* letter-spacing: 1px; */
    margin: 2vh;
    padding: 0em 0.25em 0.06em 0.24em;
    /* padding: 0.3em 0.7em 0.3em 0.1em; */
    /* font-weight: bold; */
    opacity: 0.6;
    color: rgb(150, 150, 150);
    width: fit-content;
    height: fit-content;
    border-radius: 0.5em;
    transition: background-color 0.5s;
    align-self: center;
}

#concept-store-title.closed {
    background-color: rgb(240, 240, 240);
}

#concept-store-title:hover {
    cursor: pointer;
    color: gray;
}

#concept-store-title:active {
    box-shadow: var(--boxShadowTentative);
}

.concept-in-store {
    line-height: 1.3;
    font-size: calc(var(--normal-bullet-font-size));
    padding: 0.35em var(--x-padding);
    width: fit-content;
    width: 100%;
    transition: color 0.2s !important;
    overflow: auto;
    color: rgb(96, 96, 96);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 0.4em;
    background-color: transparent;
    /* border-top: 1px solid rgb(238, 238, 238); */
}
.concept-in-store.topQueriedPage {
    background-color: var(--background-purple);
}
.concept-in-store.isMobile {
    padding: 0.5em var(--x-padding);
    margin: 0.2em 0em;
}
/* #concept-store:hover > .concept-list-in-store {
    opacity: 1;
} */

.concept-in-store.special {
    font-style: italic;
}

.concept-in-store.title {
    background-color: var(--background-purple);
    /* rgba(220, 220, 220, 0.5); */
    outline: rgb(158, 158, 158) 2px;
    color: var(--deep-blue);
    /* rgb(116, 116, 116); */
}
.concept-in-store:hover {
    cursor: default;
    border-radius: 0.4em;
}

.var-number {
    /* text-decoration: underline solid 0.5px rgb(184, 184, 184);
    text-underline-offset: 3px; */
    /* font-weight: normal; */
}
.number-text {
    /* font-size: 0.85em; */
    /* font-weight: bolder; */
    /* font-family: Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
    /* text-decoration: underline solid 0.5px rgb(184, 184, 184);
    text-underline-offset: 3px; */
    letter-spacing: 1px;
    /* text */
}
.concept-in-store.button {
    /* color: rgba(0, 0, 128, 0.79); */
    /* color: rgb(167, 72, 0); */
    border-top: 1px solid transparent;
    background-color: rgba(255, 255, 255, 0.879) !important;
    justify-content: center;
    margin: 0.3em 0.13em;
    font-size: 1.3em;
    align-self: center;
    padding: 0;
    box-shadow: 0 0 4px gainsboro;

    transition: width ease 0.4s;
    /* color: rgb(212, 212, 212); */
    color: black;

    outline: none;
}

.import-button {
    /* height: 2em;
    width: 3em; */
    font-size: 1.3em !important;

    /* font-weight: bold; */
}
.concept-in-store.button:hover {
    cursor: pointer !important;

    /* width: 100%; */
}
.concept-in-store.button:active {
    background-color: rgb(227, 231, 255) !important;

    box-shadow: none;
}
/* Required by react-animate-reorder */
/* .reorder-wrapper-item {
    position: relative;
    transition: top 1s ease-in;
} */

.starred-in-concept {
    align-self: flex-start;
}
.starred-in-concept:hover {
    cursor: pointer;
}
.concept-store-buttons {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 0.6em;
}
#concept-caret {
    color: rgb(186, 186, 186);
    transition: transform 0.15s;
    display: inline-flex;
    margin-right: 0.3em;
    font-size: medium;
    cursor: pointer;
    /*  */
    height: fit-content;
}
#concept-caret.toggles-store {
    font-size: x-large;
    color: rgb(208, 208, 208);
    cursor: pointer;
    margin: -0.5em 0;
    /* align-self: flex-end; */
}
.ninety-degrees {
    transform: rotate(90deg);
}
.one-eighty-degrees {
    transform: rotate(180deg);
}
.concept-store-button-words {
    font-size: 0.8em;
    padding: 0.3em;
    /* color: gray; */
}
.page-in-store-delete-button {
    visibility: hidden;
    color: lightgray;
    cursor: pointer;
}
.close-store-button {
    margin: 0 0.4em;
}
.search-and-caret {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.menu-icon {
    width: 1.3em;
    height: 1.3em;
    opacity: 0.6;
}
