#entities-button-container {
    border-radius: 50%;
    width: 3.2em;
    height: 3.2em;
    margin: 0 1em;
    /* background-color: rgb(245, 245, 245); */
    display: flex;
    justify-content: center;
    align-items: center;
    outline-color: white;
    outline: 2px solid rgb(232, 232, 232);
    transition: outline-color 0.1s, background-color 0.1s;
}
#entities-button-container:hover {
    outline: 1px solid rgb(225, 225, 225);

    cursor: pointer;
}
#entities-button-container:active {
    outline-color: rgb(255, 209, 219);
    background-color: rgb(255, 218, 218);
}
#plexus-logo-for-button {
    width: 2.5em;
    height: 2.25em;
    padding: 0.1em;
    padding-bottom: 0.2em;
    padding-top: 0.05em;

    padding-right: 0.14em;
}

.stupid-button {
    font-size: small;
    padding: 0.2em 1.5%;
    border-radius: 0.3em;
    cursor: pointer;
    width: fit-content;
    background-color: rgb(243, 244, 255);
    box-shadow: 0 0 4px rgb(211, 202, 255);
    color: rgb(20, 20, 255);
    transition: width 0.1s;
    align-self: center;
    margin-right: 2em;
}
.stupid-button:active {
    color: var(--electric-purple);
    outline-color: var(--background-purple);
    box-shadow: none;
    background-color: var(--background-purple);
}
