.center {
    display: flex;
    align-items: center;
    flex-direction: column;
}
.signInView {
    margin: 12%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-width: 50%;
    font-family: 'Optima';
}
.signInView > .form-group {
    width: 100%;
}
.form-group > * {
    border-radius: 0.4em;

    /* padding: 1em; */
}
.loginInput {
    font-family: 'Optima';
    font-size: var(--normal-bullet-font-size);
    box-shadow: none;
    border: none;
    outline: none;
    outline: 1px rgb(231, 216, 255) solid;

    /* border-bottom: solid 2px var(--medium-color); */
    /* border-left: solid 2px var(--medium-color); */

    /* outline: 2px var(--dark-color); */
    /* padding: 10pt; */
}
.loginInput:focus {
    border: none;
    outline: 1px lightblue solid;
    /* border-left: solid 2px transparent; */
}
.enterPlexus {
    background-color: rgb(150, 162, 247);
    font-weight: bold;
    color: white;
    min-width: 25%;
    width: fit-content;
    min-height: 30px;
    font-size: 1.5em;
    outline: none;
    border: none;
    border-radius: 0.4em;
}
.enterPlexus:active {
    background-color: var(--electric-blue) !important;
}
.reset-password-link {
    text-decoration: underline;
    cursor: pointer;
}

.sign-in-view-container {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
}
