.header-and-pages {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
}

.the-two-panels {
    display: flex;
    flex-direction: row;
    height: 100%;
    /* justify-content: space-evenly; */
}
