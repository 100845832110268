.closePageButton {
    font-size: 1.4em;
    width: fit-content;
    height: fit-content;
    margin-right: 1em;
    color: gray;
}
.closePageButton:hover {
    cursor: pointer;
}
