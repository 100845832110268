.Plexus-container {
    height: 100vh;
    overflow: visible;

    /* display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center; */
}
html {
    /* overflow:auto; */
    overflow: hidden;
}
html::-webkit-scrollbar {
    display: none;
}
body {
    width: 100%;
    /* height: 100%; */
    /* background-color: 'lightblue'; */
    overflow: visible;
}
:root {
    --purpley-lighter: rgb(156, 0, 127);
    --purpley: rgb(147, 0, 88);
    --darker-than-font-color: rgb(73, 0, 0);
    --electric-plex-color: rgb(198, 0, 86);
    --font-color: rgb(148, 25, 41);
    --grayer-font-color: rgb(189, 1, 1);
    --darker-color: rgb(200, 64, 64);
    --origin-mark-color: rgb(109, 50, 50);
    --normal-bullet-font-size: 18px;
    --dark-color: rgb(233, 108, 108);
    --plex-color: #fb7272;
    --high-color: rgb(255, 152, 152);
    --notch-color: rgb(255, 188, 188);
    --medium-color: rgb(255, 197, 197);
    --light-medium-color: rgb(254, 207, 207);
    --background-color: rgb(255, 219, 219);
    --light-background-color: rgb(255, 234, 234);
    --the-font-family: Optima, system;
    /* --very-light-background-color: rgb(255, 233, 233); */

    --light-color: rgba(255, 0, 0, 0.075);
    --very-light-color: rgb(255, 246, 246);
    /* --super-light-color: rgb(255, 248, 250); */

    --supremely-light-color: rgb(255, 250, 250);

    --dark-gray: rgb(97, 97, 97);
    --medium-gray-color: rgb(161, 160, 160);
    --gray-color: rgb(176, 176, 176);
    --light-gray: rgb(199, 199, 199);
    --more-light-gray: rgb(226, 226, 226);
    --very-light-gray: rgb(241, 241, 241);
    --very-very-light-gray: rgb(245, 245, 245);
    --bullet-fade-color: rgb(233, 224, 224);
    --indent: 42px;
    --default-font-size-transition: font-size ease 0.15s;
    --font-weight: 600;
    --sidebar-border: 2px solid rgb(241, 241, 241);
    --scrollbar-border: 3px solid rgb(228, 228, 228);
    --group-border-radius: 0.6em;
    --groupBoxShadowBackgroundColor: rgb(255, 225, 225);
    --groupBoxShadowColor: rgb(224, 224, 224);
    --groupBoxShadowBlurRadius: 4px;
    --boxShadowTentative: 0px 0px 4px 0px rgb(229, 229, 229);
    --boxShadowFull: 0px 0px var(--groupBoxShadowBlurRadius) 0px var(--groupBoxShadowColor);
    --boxShadowHover: 0px 0px var(--groupBoxShadowBlurRadius) 1px var(--groupBoxShadowColor);
    --boxShadowActive: 0px 0px calc(1 * var(--groupBoxShadowBlurRadius)) 2px
        var(--groupBoxShadowColor);
    --boxShadowSmall: 0px 0px calc(0.6 * var(--groupBoxShadowBlurRadius)) 0px
        var(--groupBoxShadowColor);

    --boxShadowFullWithBackground: 0px 0px calc(1 * var(--groupBoxShadowBlurRadius)) 0px
        var(--groupBoxShadowBackgroundColor);

    --black-font-color: #212529;

    --electric-purple: rgb(55, 0, 255);
    --deep-blue: rgb(0, 82, 205);
    --electric-blue: rgb(67, 29, 255);
    --sky-electric-blue: rgb(115, 143, 255);

    --light-aqua: rgb(122, 227, 202);

    --default-purple: rgb(43, 43, 184);
    --light-purple: rgb(208, 221, 255);

    --background-purple: rgb(232, 232, 255);
    --grayed-purple: rgba(63, 47, 123, 0.6);
}

#root {
    overflow: visible;
}
#whole-thing {
    /* margin: 0px 40px 0px 40px; */
    overflow: visible;
    font-family: Optima, system;
    font-family: var(--the-font-family);
    /* Optima, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', */
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    letter-spacing: 0pt;
    /* background-color: rgb(253, 25, 253); */
}

.button {
    font-family: Optima, system;
    font-family: var(--the-font-family);
}
.loginBtn {
    font-size: 10px;
    padding: 8px;
    margin: 0px;
    border-width: 0px;
    line-height: normal;
    height: auto;
    /* padding: 5px; */
    /* height: 36px; */
    /* text-align: right; */
    /* display: flex; */
}

/* Title bullet */
.titleBullet {
    /* border-bottom: 2px solid var(--background-color); */
    /* text-align: center; */
    display: flex;
    overflow-wrap: normal;
    /* font-weight: var(--font-weight); */
    font-weight: bold;
    font-size: calc(2.3 * 18px);
    font-size: calc(2.3 * var(--normal-bullet-font-size));
    overflow: visible;
    transition: contents ease 0.5s;
    /* position: relative; */
    margin: 0px 0px calc(0.5 * 18px) 0px;
    margin: 0px 0px calc(0.5 * var(--normal-bullet-font-size)) 0px;
    /* width: 100%; */
    /* text-decoration: underline; */
    /* text-align: center; */
    /* width: 50px; */
}
.rotate-up-point {
    -webkit-transform: rotate(30deg);
            transform: rotate(30deg);
    transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    transition: transform 0.2s, -webkit-transform 0.2s;
}
.rotate-up {
    -webkit-transform: rotate(33deg);
            transform: rotate(33deg);
    transition: -webkit-transform 0.5s;
    transition: transform 0.5s;
    transition: transform 0.5s, -webkit-transform 0.5s;
}
.rotate-down {
    -webkit-transform: rotate(328deg);
            transform: rotate(328deg);
    transition: -webkit-transform 0.5s;
    transition: transform 0.5s;
    transition: transform 0.5s, -webkit-transform 0.5s;
}

#title {
    /* font-family: var(--the-font-family); */
    /* top: 100px; */
}

.titleWrapper {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
}

.addSymbol {
    position: relative;
    height: 0ex;
    font-size: 2.4ex;
    padding: 4px;
    /* text-align: center; */
    padding-top: 0px;
    color: rgb(255, 197, 197);
    color: var(--medium-color);
    z-index: 1;
}

.addSymbol:hover {
    color: #fb7272;
    color: var(--plex-color);
    cursor: pointer;
}

#cy-container {
    /* margin: 10px; */
    display: flex;
    /* float: right; */
    align-self: flex-start;
    text-align: center;
    /* justify-self: flex-end; */
    margin-right: 3em;
    margin-bottom: 10em;
    flex-grow: 100;
    /* color: red; */
    /* width: 100px; */
    width: 20%;
    height: 72vh;
    /* width: 200px; */
    /* height: 200px; */
    /* margin: 4em; */
}
#cy-container > * {
    display: flex;
}
/* 
  @media only screen and (max-width: 100vh) {
    #cy-container {
        display: none;
    }
  } */

/* BULLETS */
.subBullets {
    display: flex;
    flex-direction: column;
    text-align: left;
}
/* .neighbors::-webkit-scrollbar { width: 1 !important } */

.neighbors {
    scrollbar-color: light;
    justify-content: flex-start;
    /* max-width: 40%; */
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    /* display: flex; */
    /* float: left; */
    /* display: inline-block; */

    /* position: visible; */
}
/* .neighbors > *{
  scroll-snap-align: end;
} */
/* .neighbors::after {
  scroll-snap-align: end;
  display: block;
} */
.fullHeight {
    overflow-y: auto;
    -ms-scroll-snap-type: y proximity;
        scroll-snap-type: y proximity;
    scroll-padding-bottom: 15px;
    /* scroll-snap-points-y: repeat(100vh); */
    height: 70vh;
}
.fullHeight > * {
    scroll-snap-align: start;
}
.nicky {
    overflow-y: visible;
    display: flex;
    flex-direction: column;
}
/* More bullets */
.indented {
    margin-left: 42px;
    margin-left: var(--indent);
    display: flex;
    flex-direction: column;
}

/* not sure why this works but the above does not */
input {
    font-family: Optima, system;
    font-family: var(--the-font-family);
    /* font-size: calc(var(--normal-bullet-font-size)) !important; */
    /* width: 60vh !important; */
}

input [type='text'] {
    font-family: Optima, system !important;
    font-family: var(--the-font-family) !important;
}

/* input[type='text']:not(.browser-default):focus {
    border-bottom: solid thin var(--plex-color) !important;
    box-shadow: 0 1px 0 0 var(--plex-color) !important;
} */

.levelOneBullet {
    padding-bottom: 1.4em;
}
.normalBullet {
    /* text-align: center; */
    /* color: var(--plex-color);  */
    /* color: rgb(118, 118, 118); */
    /* font-family: var(--the-font-family); */
    /* font-size: 2ex; */
    border-radius: 8px;
    display: block;
    /* padding-top: 8px; */
    margin-left: 32px;
    /* margin-bottom: 12px; */
    /* padding-bottom: calc(.5 * var(--normal-bullet-font-size)); */
    /* height: 40px; */
    /* align-items: center; */
    transition: margin-bottom ease 0.14s;
    transition: opacity ease 0.5s;
    /* width: fit-content; */
    /* display: flex; */
}
/* .point:hover .normalBullet{
  color: var(--plex-color);
} */
.gray {
    color: rgb(176, 176, 176);
    color: var(--gray-color);
    font-weight: lighter;
    /* font-size: 1.5ex; */
}

/* .square{
  background-color: transparent;
  width: calc(0.8*var(--normal-bullet-font-size));
  height: calc(0.8*var(--normal-bullet-font-size));
  border: solid .2ex;
  transition: width .1s, height .1s;
} */

.square {
    cursor: pointer;
}
.square:hover:not(.full) {
    width: 2ex;
    height: 2ex;
    background-color: rgb(255, 219, 219);
    background-color: var(--background-color);
}

.internalBullet {
    /* text-align: center; */
    display: flex;
    flex-direction: row;
    align-items: center;
    /* width: fit-content; */
    /* margin-left:  */
    /* vertical-align: middle; */
    /* padding: 4px; */
}
/* .internalBullet{
} */
/* .normalBullet:hover{
  padding: 0px;
  margin: 0px;
} */
/* .normalBullet:hover{cursor: pointer;} */
.concept:hover {
    /* background-color: rgb(255, 225, 225); */
    /* color: var(--plex-color); */
    cursor: pointer;
}
.normal-concept-hover {
    margin-bottom: 0;
}
.concept-hover {
    /* background-color: rgb(255, 225, 225); */
    /* color: var(--plex-color); */
    cursor: pointer;
    font-size: 2.5ex;
}
.close {
    font-size: 0.5em;
    visibility: hidden;
    border-radius: 6px;
    color: #fb7272;
    color: var(--plex-color);
}
.close:hover {
    cursor: pointer;
    /* background-color:  rgb(255, 219, 219); */
}
.predicate:hover .close {
    visibility: visible;
    background-color: rgb(255, 173, 173);
}
.separator {
    padding: 4px;
    padding-left: 0px;
}
.stack {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.buttonNew {
    background-color: rgb(255, 197, 197);
    background-color: var(--medium-color);
    margin: 0.5em 0em;
    padding: 0 1em;
    /* padding-bottom: 5px; */
    /* height: 2.5em; */
    border: 0;
    vertical-align: middle;
    /* padding: 8px; */
    /* font-weight: bold; */
}
.buttonBar {
    visibility: hidden;
    display: flex;
    flex-direction: row;
    /* justify-content: :  */
    justify-content: center;
    margin-left: 16px;
    color: rgb(255, 197, 197);
    color: var(--medium-color);
}
.buttonBar > * {
    /* display: fle; */
    margin: 0px 0px;
    /* align-self: center; */

    padding: 4px 8px;
    cursor: pointer;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    display: block;
    transition: -webkit-transform 0.2s ease 0.2s;
    transition: transform 0.2s ease 0.2s;
    transition: transform 0.2s ease 0.2s, -webkit-transform 0.2s ease 0.2s;
    transition: font-size ease 0.15s;
    transition: var(--default-font-size-transition);
    transform: rotate(0deg);
    display: flex;
    flex-direction: row;

    /* margin: 0px 20px; */
}
/* .editNode{
  padding: 0px 0px;
  margin-bottom: 4px;
} */
.nodify {
    align-self: center;
}
.addBeforeEdge {
    padding-right: 0px;
    margin-right: 0px;
}
.addAfterEdge {
    padding-left: 0px;
    margin-left: 0px;
}
.buttonBar > *:hover {
    color: #fb7272;
    color: var(--plex-color);
    /* transform: (90deg); */
    /* font-size: 3ex; */
}
.editNode:hover {
    color: rgb(255, 197, 197);
    color: var(--medium-color);
}
/* .addAfterEdge{
  transform: rotate(-83deg);
} */
/* .addBeforeEdge{
  transform: rotate(0deg);
}  */
.relatSymbol {
    margin-right: 0px;
    padding-right: 0px;
    padding: 0px 4px;
    font-size: 1.8ex;
}
.editNode {
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 0px;
    padding-right: 0px;
    font-size: 4ex;
}
.relatSymbol:hover {
    /* font-weight: var(--font-weight); */
    color: #fb7272;
    color: var(--plex-color);
}
.editNode:hover {
    /* font-size: 4.4ex; */
}
.internalBullet:hover .buttonBar {
    visibility: visible;
}
.showMore {
    /* padding: 4px; */
    transition: -webkit-transform 0.2s ease;
    transition: transform 0.2s ease;
    transition: transform 0.2s ease, -webkit-transform 0.2s ease;
}
.showMore:hover {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    transition: -webkit-transform 0.2s ease;
    transition: transform 0.2s ease;
    transition: transform 0.2s ease, -webkit-transform 0.2s ease;
}

#xOut {
    /* float: right; */
    /* vertical-align: middle; */
    /* align-self: center; */
    font-size: 1.2ex;
    /* border-radius: 6px; */
    visibility: hidden;
}
.internalBullet:hover #xOut {
    visibility: visible;
}
#xOut:hover {
    /* background-color: rgb(255, 173, 173); 
  cursor: pointer;
  background-color:  rgb(255, 219, 219); */
    /* font-size: 1.8ex; */
}
.predicate:hover {
    cursor: text;
}
.selected-bullet,
.selected-bullet:hover {
    /* color: white; */
    /* background-color: var(--plex-color); */
    border-left: 6px solid #fb7272;
    border-left: 6px solid var(--plex-color);
}
.concept,
.contentEditable {
    padding: 4px;
    padding-right: 0px;
    padding-left: 0px;
    /* padding-bottom: 0px; */

    /* -webkit-animation-duration: 10s;animation-duration: 10s;
  -webkit-animation-fill-mode: both;animation-fill-mode: both; */
    transition: width ease 0.5s;
}
.fadeOut {
    /* transform: translateX(220px); */
    width: 400px;
    opacity: 0;
}
/* @-webkit-keyframes fadeOut {
  0% {opacity: 1;}
  100% {opacity: 0;}
}

@keyframes fadeOut {
  0% {opacity: 1;}
  100% {opacity: 0; width: 400px;}
}
.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
} */
.concept {
    font-weight: 600;
    border-radius: 6px;
    padding: 0.3em 0;
    transition: font-size ease 0.15s;
    /* color: var(--plex-color); */
}
.bold {
    font-weight: 600;
    font-weight: var(--font-weight);
}
.temp {
    /* margin-top: 40px; */
}
.predicate {
    /* font-style: italic; */
    /* text-decoration: underline; */
    /* font-size: .8em; */
    /* padding-right: 5px; */
    outline: 0;
    /* color:navy; */
    /* font-size: 1.8ex; */
    /* var(--dark-color); */
}
.contentEditable {
    /* height: calc(var(--normal-bullet-font-size)); */
    /* padding: 0px; */
}
.showSuggestions {
    justify-self: center;
    padding: 4px;
    width: calc(0.6 * 18px);
    width: calc(0.6 * var(--normal-bullet-font-size));
    height: calc(0.6 * 18px);
    height: calc(0.6 * var(--normal-bullet-font-size));
    border-radius: 0px;
    transition: width 0.1s, height 0.1s;
    background-color: transparent;
    outline: solid 0.2ex #fb7272;
    outline: solid 0.2ex var(--plex-color);
    outline-offset: 1px;
}
.showSuggestions:hover {
    cursor: pointer;
}
.suggestions-hover {
    background-color: lightgray;
}

/* .showSuggestions:hover{
 width: calc(.7* var(--normal-bullet-font-size));
  height: calc(.7* var(--normal-bullet-font-size));
} */
.full {
    /* max-height: 40px; */
    background-color: rgb(176, 176, 176);
    background-color: var(--gray-color);
    /* var(--plex-color); */
}
.smaller {
    font-size: 1.4ex;
}

/* .gray>*{
  background-color: none;
} */

/* Record kept on top left */
.pastOne {
    /* margin: 4px; */
    margin-left: 8px;
    border-radius: 4px;
    padding: 4px 0px;
    vertical-align: text-top;
    transition: font-size ease 0.15s;
}
.pastOne:hover {
    font-size: 2.3ex;
}
#lastFive {
    margin: 8px;
    cursor: pointer;
    height: 16px;
    font-size: 1.8ex;
}
#saveSignal {
    font-size: 1.2ex;
    text-align: center;
    align-self: center;
    font-style: italic;
    margin-right: 2ex;
    /* border-radius: 1.4ex;
  border: solid 1px; */
}
#saveLoader {
    width: calc(1.2 * 18px);
    width: calc(1.2 * var(--normal-bullet-font-size));
}
.pumpingLoader {
    width: 80pt;
    margin-left: 8pt;
    /* align-self: center; */
}

/* Input Bars */
#addingBar {
    margin-top: 0px;
    font-size: 56px;
    display: flex;
    align-items: center;
    height: 50px;
}
.edgeSymbol:hover .internalSymbol {
    display: none;
}
.nodeSymbol:hover .internalSymbol {
    display: none;
}
.edgeSymbol {
    margin-left: 32px;
}
.edgeSymbol:hover:before {
    content: '\2192';
}
.nodeSymbol:hover:before {
    content: '\25CB';
}
/* #addingBar:hover:before{
  content: '→'
} */
.nodeSymbol {
    font-size: 100px;
    margin-bottom: 10px;
    width: 100px;
}
.titleBullet:focus,
.predicate:focus {
    outline: 0;
}
#inputBars {
    margin-left: 32px;
    display: flex;
    color: #fb7272;
    color: var(--plex-color);
    /* width: 100%; */
    /* height: 80px; */
}
.bar {
    /* width: 40vh; */
    /* color: var(--plex-color); */
    margin: 8px 0px 0px 0px;
    /* position: fixed; */
    /* bottom: 7%; */
    display: flex;
    outline: none;
    /* justify-content: space-between; */
    align-items: baseline;

    text-align: center;
    /* border-width: 0 0 2px; */
    /* height: 65px; */
    /* width: 40vh; */
}
.bar > * {
    /* width: 100%; */
    /* width: 30vh; */
    /* margin-right: 24px; */
    outline: 0px;
}

.innerBar {
    /* width: 36%;  */
    /* float:right; */
    display: inline-block;
    margin: 1em 6em;
}
/* .innerBar>*, .innerBar>*>*{
  width: 100%;
}  */
#input-box-for-searching {
    display: inline;
    /* width: 100%; */
}

.inputBar {
    outline: none;
    border-width: 0;
    border-bottom-width: 'thin';
    /* min-width: 15vh; */
    /* display: flex;
  flex-direction: column; */
    /* align-items: baseline; */
    /* height: 8ex; */
    /* width: 45vh;
  margin: 0px 32px; */
    /* width: 300px; */
}
.nounBar > * > * > *,
.nounBar > * > * > * > * {
    font-weight: 600;
    font-weight: var(--font-weight);
}

/* #parent {
  list-style: none;
  width: 100%;
  height: 90px;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
} */
#reverseButton {
    align-self: center;
    /* visibility: hidden; */
    width: 18px;
    width: var(--normal-bullet-font-size);
    transition: visibility 0.1s, font-size 0.14s;
    border-radius: 8px;
}
#inputBars:hover #reverseButton {
    visibility: visible;
}
#reverseButton:hover {
    cursor: pointer;
}
#prepSymbol,
#nounSymbol,
.point {
    align-self: center;
}
.symbol {
    font-size: calc(0.8 * 18px);
    font-size: calc(0.8 * var(--normal-bullet-font-size));
    cursor: pointer;
    transition: font-size 0.14s, width 0.14s;
    color: rgb(255, 225, 225);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 10px;
    /* padding: 10px; */
    /* font-size: 100px; */
}
.symbol > *:hover {
    color: #fb7272;
    color: var(--plex-color);
    /* padding: 2px; */
}
.symbol-hover {
    color: #fb7272;
    color: var(--plex-color);
    /* font-size: var(--normal-bullet-font-size); */
}

#searchSymbol {
    width: 25px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.search-symbol-hover {
    width: 40px;
}
.option {
    font-size: 2.2ex;
    color: rgb(233, 108, 108);
    color: var(--dark-color);
    /* text-align: center; */
}
.small {
    /* font-size: 1.5ex; */
    /* color: var(--dark-color); */
    /* background-color: var(--plex-color); */
    overflow-wrap: wrap;
}
.hidden {
    visibility: hidden;
}
.sm {
    font-size: 12px;
    background-color: rgb(176, 176, 176);
    background-color: var(--gray-color);
    /* padding: 4px; */
}
.sm:hover {
    background-color: #fb7272;
    background-color: var(--plex-color);
}

#brand {
    /* text-align: center; */
    color: #fb7272;
    color: var(--plex-color);
    font-family: Optima, system;
    font-family: var(--the-font-family);
    font-style: italic;
    font-size: 32px;
    /* text-align: center; */
}
.indicators {
    display: flex;
    /* justify-content: space-evenly; */
    align-items: center;
    /* justify-content:  */
}

/* Components on side */
.component:hover {
    background-color: rgb(255, 225, 225);
    color: #fb7272;
    color: var(--plex-color);

    /* cursor: pointer; */
}

#plexusLogo {
    width: calc(1.1 * 18px);
    width: calc(1.1 * var(--normal-bullet-font-size));
    /* transform: rotate(33deg) */
}

/* MISC */
.button-container {
    /* float:left; */
    display: inline-block;
    white-space: nowrap;
    overflow-x: auto;
    /* top: 100px; */
    /* display: flex;
  justify-content: space-between; */
}
.newButton {
    margin: 0% 0.2em;
    /* color: var(--plex-color); */
    /* display: flex;
  justify-content: space-between; */
}
#top {
    display: flex;
    justify-content: space-between;
}
.button1 {
    position: relative;
    margin: auto;
    height: 30px;
    width: 80px;
    border-radius: 30%;
    cursor: pointer;
    border: 2px solid #fb7272;
    border: 2px solid var(--plex-color);
}

/* Cytoscape Graph Visualization*/
#cy {
    /* height: 90%; */
    /* width: 100%; */
    z-index: 1;
    display: block;
}
#lasttwo {
    /* text-align: center; */
    /* display: inline-block;
  font-family: var(--the-font-family);
  font-style: italic;
  margin: 0% 2em;
  font-size: 24px; */
}
#addNodeButton {
    /* border-radius: 40% */
}
.potential {
    color: #00008b;
}

#above {
    /* display: flex;
  justify-content: left; */
    color: #fb7272;
    color: var(--plex-color);
    text-align: left;
}

/* Loading */
#loading {
    margin-top: 10%;
    /* height: 80%; */
    /* justify-content: sp; */
    align-items: center;
    display: flex;
    flex-direction: column;
    color: #fb7272;
    color: var(--plex-color);
    /* background-color: rgb(0, 9, 85);; */
    font-size: 7em;
    text-align: center;
    font-family: 'Optima', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    font-style: italic;
}
#loading > * {
    padding-top: 3vh;
}

/* Zoom */
#zoomIcon {
    height: 26px;
    width: 26px;
    display: inline-block;
    /* top: 10px; */
}
#zoomLevel {
    font-size: 28px;
    border-radius: 4px;
    display: inline-block;
    color: rgb(85, 133, 254);
    /* border: dotted; */
    /* border-width: 5px; */
}
#zoom {
    font-style: 600;
    font-style: var(--font-weight);
    /* margin: 0px 1em; */
    /* float: left; */
    display: inline-block;
    font-family: 'Courier';
    position: relative;
    /* top: 5px; */
}

/* Saved snackbar */
#snackbar {
    visibility: hidden;
    min-width: 50px;
    /* margin-left: -400px; */
    color: #fb7272;
    color: var(--plex-color);
    background-color: white;
    text-align: center;
    border-radius: 10px;
    border: 1px solid #fb7272;
    border: 1px solid var(--plex-color);
    padding: 16px;
    position: absolute;
    opacity: 0.9;
    z-index: 1;
    left: 20px;
    bottom: 30px;
    font-size: 36px;
    font-style: italic;
    font-family: 'Apple Chancery, cursive';
}
#snackbar.show {
    visibility: visible;
    -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
    from {
        bottom: 0;
        opacity: 0;
    }
    to {
        bottom: 30px;
        opacity: 0.9;
    }
}

@keyframes fadein {
    from {
        bottom: 0;
        opacity: 0;
    }
    to {
        bottom: 30px;
        opacity: 0.9;
    }
}

@-webkit-keyframes fadeout {
    from {
        bottom: 30px;
        opacity: 0.9;
    }
    to {
        bottom: 0;
        opacity: 0;
    }
}

@keyframes fadeout {
    from {
        bottom: 30px;
        opacity: 0.9;
    }
    to {
        bottom: 0;
        opacity: 0;
    }
}
@-webkit-keyframes jump {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
@keyframes jump {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.jumpie {
    -webkit-animation-duration: 1s;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-timing-function: linear;
}

/* SLIDER */
/* The slider itself */
.slider {
    -webkit-appearance: none; /* Override default CSS styles */
    appearance: none;
    width: 100%; /* Full-width */
    height: 25px; /* Specified height */
    background: #d3d3d3; /* Grey background */
    outline: none; /* Remove outline */
    opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */ /* 0.2 seconds transition on hover */
    transition: opacity 0.2s;
}

/* Mouse-over effects */
.slider:hover {
    opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 25px; /* Set a specific slider handle width */
    height: 25px; /* Slider handle height */
    background: #4caf50; /* Green background */
    cursor: pointer; /* Cursor on hover */
}

.slider::-moz-range-thumb {
    width: 25px; /* Set a specific slider handle width */
    height: 25px; /* Slider handle height */
    background: #4caf50; /* Green background */
    cursor: pointer; /* Cursor on hover */
}

.shortcuts-container {
    display: flex;
    flex-direction: row;
    margin: 0ex 0ex;
    margin-left: 1.2ex;
}
.shortcuts {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 2ex;
    overflow-y: auto;
}
.shortcut {
    padding: 0.4ex;
    font-size: calc(0.8 * 18px);
    font-size: calc(0.8 * var(--normal-bullet-font-size));
}
.shortcutItems {
}

/* MENTIONS UTILIZED IN Note.tsx */

.mentions {
    position: relative;
    /* margin: 1em 0; */
    width: 75%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: inline-block;
}

/* Elimated black border with one of these, but forgot which */
.mentions--singleLine .mentions__control {
    display: inline-block;
    width: 100%;
}
.mentions--singleLine .mentions__highlighter {
    padding: 1px;
    border: 2px inset transparent;
}
.mentions--singleLine .mentions__input {
    padding: 1px;
    /* padding: 2px; */
    border: 2px transparent inset;
    outline: none;
}

.mentions--multiLine .mentions__control {
    font-family: 'Optima';
    font-size: 14pt;
}
.mentions--multiLine .mentions__highlighter {
    border: 0px solid transparent;
    border-bottom: 2px solid;
    /* var(--medium-color); */
    padding: 9px;
    min-height: 63px;
}
.mentions--multiLine .mentions__input {
    border: 0px solid silver;
    border-bottom: 2px solid transparent;
    /* var(--medium-color); */
    padding: 9px;
    outline: 0;
}

.mentions__suggestions__list {
    background-color: white;
    /* border: 1px solid rgba(0, 0, 0, 0.15); */
    font-size: 10pt;
}

.mentions__suggestions__item {
    padding: 5px 15px;
    border-radius: 0.5em;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    /* border-bottom: 1px solid rgba(0, 0, 0, 0.15); */
}

.mentions__suggestions__item--focused {
    background-color: #cee4e5;
}

.mentions__mention {
    z-index: -100;
    color: rgb(255, 152, 152);
    color: var(--high-color);
    text-decoration: underline !important;
    pointer-events: none;
}

.breadcrumbs {
    margin-right: 20px;
    margin-left: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.breadcrumb {
    margin: 5px;
}
.top-ribbon {
    padding: 0.8em;
    padding-left: 1.4em;
    padding-bottom: 0;
    position: relative;
    display: flex;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-self: flex-start;
    /* background-color: rgb(221, 221, 221); */
    /* box-shadow: var(--boxShadowSmall); */
    /* border-bottom: 1px rgb(236, 236, 236) solid; */
    /* margin: 0 4vw; */
}

.plexRow {
    display: flex;
    flex-direction: row;
}
.alignItemsCenter {
    align-items: center;
}
.alignItemsStart {
    align-items: flex-start;
}

.plexColumn {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* transition: position 1s; */
}
.plexColumn + .plexColumn {
}
.pages-container {
    display: flex;
    flex-direction: row;
    width: 100vw;
    /* width: 97vw; */
    height: 100%;
    align-items: center;
    flex: 1 1;
    /* justify-content: flex-start; */
    justify-content: space-between;
    align-self: center;
    margin: 0 6px;
}
.panel {
    padding: 1% 2.5%;
    flex: 2 1;
    /* padding: 0 1vw; */
    display: flex;
    flex-direction: column;
    /* justify-content: flex-start; */
    /* justify-self: center; */
    align-items: flex-start;
    height: -webkit-max-content;
    height: max-content;
    height: 100%;
    max-width: 75em;
    min-width: 20pt;
    border-radius: 10pt;
    /* background-color: white; */
    /* margin: 0 30vw; */
    margin-top: 1vh;
    margin-bottom: 1vh;
    overflow-y: auto;
}

@media all and (max-width: 390pt) {
    .panel.conceptStoreOpen {
        display: none;
        flex: 0 1;
    }
    .pages-container {
        justify-content: center;
    }
    #concept-store-container {
        max-width: none !important;
    }
    .panel.secondary.conceptStoreOpen {
        display: none;
        flex: 0 1;
    }
}

.panel.secondary {
    flex: 1 1;
    max-width: 32vw;
    box-shadow: 0 0 4px gainsboro;

    /* background-color: rgb(250, 250, 250); */
}
.plexColumn:hover {
    cursor: default;
}

.sidebar-link {
    line-height: 24px;
    padding-top: 0px;
    padding-bottom: 0px;
}
.sidebar-link:hover {
    background-color: gainsboro;
}

/* .predicate .concept #xOut{
    
  } */

.selected-component {
    border-color: red;
    border-width: 4px;
    background-color: rgb(255, 225, 225);
}
#plexusLogo:hover {
    /* background-color: rgb(0, 17, 174); */
    /* background-color: rgb(255, 225, 225);
    border-radius: 8px; */
}
#components-title {
    margin: 18px;
}
.sidebar {
    /* display: flex; */

    position: fixed;
    top: 0;
    right: 0px;
    bottom: 0;
    font-family: 'Optima';
    font-size: 24px;
    color: rgb(75, 81, 85);
    background: rgba(255, 255, 255, 0.932);
    transition: -webkit-transform 0.5s ease 0s;
    transition: transform 0.5s ease 0s;
    transition: transform 0.5s ease 0s, -webkit-transform 0.5s ease 0s;
    z-index: 2;
    min-width: 220px;
    /* overflow-y: auto; */
    /* width: 20%; */
}
.sidebar-in {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
}
.sidebar-baby {
    line-height: 24px;
}
.right-sidebar-arrow {
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 100%;
    /* width: 32px; */
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    cursor: default;
    padding-left: 2px;
    padding-right: 2px;
    border-left: 1px solid rgb(236, 238, 240);
    background: rgba(255, 255, 255, 0.932);

    /* border-right: 1px solid rgb(236, 238, 240); */
    /* background: var(--very-light-gray); */
    /* border-left: solid 1px lightgray; */
}
.right-sidebar-arrow:hover {
    background: rgb(255, 219, 219);
    cursor: pointer;
}
.components {
    color: var(--plex-color);
    font-family: var(--the-font-family);
    overflow-y: auto;
}
.component {
    border-top: 1px solid rgb(236, 238, 240);
    border-bottom: 1px solid rgb(236, 238, 240);
    margin: 0px 4px 4px 4px;
    border-radius: 6px;
    font-weight: var(--font-weight);
    font-size: 12px;
}
#components-title {
    /* text-align: center; */
    font-size: 32px;
    text-align: center;
    font-style: italic;
    padding: 0px 0.3em;
}

.settings {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: left;
    padding: 0px 10px 0px 10px;
}
.settingsTitle {
    padding: 7px 0px;
    align-self: center;
    font-size: 16pt;
}
.labelTag {
    font-size: 16px;
    font-weight: 400;
    padding: 4px;
}
.actions > * {
    margin: 0.3em;
}
.settingsLink {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: black;
    border-radius: 0.4em;
    background-color: rgb(238, 238, 238);
    font-size: 14px;
    padding: 0.5em;
    margin: 0.5em;
    text-decoration: none;
}
.settingsLink:hover {
    background-color: rgb(233, 233, 233);
    text-decoration: none;
    color: black;
}
.settingsLink:hover {
    cursor: pointer;
}

:root {
    --x-padding: 0.2em;
    /* --concept-store-width: 18vw; */
}
#concept-store-container {
    /* width: 0; */
    height: 100%; /* max-width: 260pt; */
    /* width: fit-content; */
    max-width: 300pt;
    min-width: 140pt;
    flex: 1 1;
    padding: 1em 1em;
    /* padding-top: 4em; */
    display: flex;
    justify-content: top;
    flex-direction: column;
    background-color: rgb(240, 240, 240);
    /* inset: 2px rgb(242, 242, 242) solid; */
    border-radius: 8px;
    /* margin-bottom: 6em; */
}

#concept-store {
    font-size: calc(0.9 * var(--normal-bullet-font-size));
    /* height: 90%; */
    /* padding: 0.2em 0.5em; */
    padding-bottom: 0;
    /* margin: 0.5em; */
    margin-top: 0;
    margin-bottom: 0px;
    /* padding-top: 0.5em; */
    color: gray;
    display: flex;
    flex-direction: column;
    border-radius: 0.4em;
    align-items: stretch;
}
#concept-store:hover {
    cursor: default;
}

.concept-list-in-store {
    max-height: 90vh;
    height: inherit;
    padding: 0 !important;
    overflow: auto;
    opacity: 1;
    transition: opacity 0.5s;
}
.concept-list-in-store::-webkit-scrollbar {
    width: 0.7em;
}

.concept-list-in-store::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
}

.concept-list-in-store::-webkit-scrollbar-thumb {
    /* background-color: rgb(255, 215, 215); */
    background-color: transparent;
    border-radius: 3px;
    /* outline: 1px solid slategrey; */
}
/*  */
/* @media all and (max-width: 500pt) {
    #concept-store-container {
        display: none;
    }
} */
#concept-store-title {
    position: absolute;
    left: 0;
    top: 0;
    font-size: calc(0.9 * var(--normal-bullet-font-size));
    /* letter-spacing: 1px; */
    margin: 2vh;
    padding: 0em 0.25em 0.06em 0.24em;
    /* padding: 0.3em 0.7em 0.3em 0.1em; */
    /* font-weight: bold; */
    opacity: 0.6;
    color: rgb(150, 150, 150);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 0.5em;
    transition: background-color 0.5s;
    align-self: center;
}

#concept-store-title.closed {
    background-color: rgb(240, 240, 240);
}

#concept-store-title:hover {
    cursor: pointer;
    color: gray;
}

#concept-store-title:active {
    box-shadow: var(--boxShadowTentative);
}

.concept-in-store {
    line-height: 1.3;
    font-size: calc(var(--normal-bullet-font-size));
    padding: 0.35em 0.2em;
    padding: 0.35em var(--x-padding);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    width: 100%;
    transition: color 0.2s !important;
    overflow: auto;
    color: rgb(96, 96, 96);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 0.4em;
    background-color: transparent;
    /* border-top: 1px solid rgb(238, 238, 238); */
}
.concept-in-store.topQueriedPage {
    background-color: var(--background-purple);
}
.concept-in-store.isMobile {
    padding: 0.5em 0.2em;
    padding: 0.5em var(--x-padding);
    margin: 0.2em 0em;
}
/* #concept-store:hover > .concept-list-in-store {
    opacity: 1;
} */

.concept-in-store.special {
    font-style: italic;
}

.concept-in-store.title {
    background-color: var(--background-purple);
    /* rgba(220, 220, 220, 0.5); */
    outline: rgb(158, 158, 158) 2px;
    color: var(--deep-blue);
    /* rgb(116, 116, 116); */
}
.concept-in-store:hover {
    cursor: default;
    border-radius: 0.4em;
}

.var-number {
    /* text-decoration: underline solid 0.5px rgb(184, 184, 184);
    text-underline-offset: 3px; */
    /* font-weight: normal; */
}
.number-text {
    /* font-size: 0.85em; */
    /* font-weight: bolder; */
    /* font-family: Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
    /* text-decoration: underline solid 0.5px rgb(184, 184, 184);
    text-underline-offset: 3px; */
    letter-spacing: 1px;
    /* text */
}
.concept-in-store.button {
    /* color: rgba(0, 0, 128, 0.79); */
    /* color: rgb(167, 72, 0); */
    border-top: 1px solid transparent;
    background-color: rgba(255, 255, 255, 0.879) !important;
    justify-content: center;
    margin: 0.3em 0.13em;
    font-size: 1.3em;
    align-self: center;
    padding: 0;
    box-shadow: 0 0 4px gainsboro;

    transition: width ease 0.4s;
    /* color: rgb(212, 212, 212); */
    color: black;

    outline: none;
}

.import-button {
    /* height: 2em;
    width: 3em; */
    font-size: 1.3em !important;

    /* font-weight: bold; */
}
.concept-in-store.button:hover {
    cursor: pointer !important;

    /* width: 100%; */
}
.concept-in-store.button:active {
    background-color: rgb(227, 231, 255) !important;

    box-shadow: none;
}
/* Required by react-animate-reorder */
/* .reorder-wrapper-item {
    position: relative;
    transition: top 1s ease-in;
} */

.starred-in-concept {
    align-self: flex-start;
}
.starred-in-concept:hover {
    cursor: pointer;
}
.concept-store-buttons {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 0.6em;
}
#concept-caret {
    color: rgb(186, 186, 186);
    transition: -webkit-transform 0.15s;
    transition: transform 0.15s;
    transition: transform 0.15s, -webkit-transform 0.15s;
    display: inline-flex;
    margin-right: 0.3em;
    font-size: medium;
    cursor: pointer;
    /*  */
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}
#concept-caret.toggles-store {
    font-size: x-large;
    color: rgb(208, 208, 208);
    cursor: pointer;
    margin: -0.5em 0;
    /* align-self: flex-end; */
}
.ninety-degrees {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}
.one-eighty-degrees {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}
.concept-store-button-words {
    font-size: 0.8em;
    padding: 0.3em;
    /* color: gray; */
}
.page-in-store-delete-button {
    visibility: hidden;
    color: lightgray;
    cursor: pointer;
}
.close-store-button {
    margin: 0 0.4em;
}
.search-and-caret {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.menu-icon {
    width: 1.3em;
    height: 1.3em;
    opacity: 0.6;
}

.search-pages {
    margin: 0 0em 0.5em;
    padding-left: 0.5em;
    /* width: 96%; */
    display: flex;
    align-self: center;
    /* background-color: rgb(242, 242, 242) !important; */
    /* rgba(247, 247, 247, 0.5) !important; */
    border-radius: 0.5em;
    outline: 2px rgb(233, 233, 233) solid;
    background-color: white;
}
.search-pages > div {
    width: 100%;
    /* that's ^ for search text to text up all of search pages */
}
.search-pages:hover {
    cursor: text;
}
.search-pages:focus-within {
    outline: none;
    /* box-shadow: 0 0 8px gainsboro; */
}

.page-in-store-caret {
    margin-right: 0.1em !important;
    color: rgb(201, 198, 230);
    font-size: 1.3em !important;
    transition: color 0.2s, -webkit-transform 0.15s;
    transition: transform 0.15s, color 0.2s;
    transition: transform 0.15s, color 0.2s, -webkit-transform 0.15s;
    display: inline-flex;
    padding: 0 0.3em;
    font-size: medium;
}
.page-in-store-caret.ninety-degrees {
    color: rgb(227, 227, 227);
}
.page-tree-in-store {
    background-color: transparent;

    border-radius: 10px;
}
/* .page-tree-in-store.dragover {
    outline: 2px var(--light-purple) solid;
} */

/* for the right sidebar */

.sidebar-arrow {
    /* border-bottom: var(--sidebar-border); */
    /* border-top: var(--sidebar-border); */
    background-color: rgb(238, 237, 237);
    /* width: 21px; */
    align-self: center;
    /* margin: 32vh 0; */
    /* height: 20%; */
}

.sidebar-arrow:hover {
    background: var(--background-color);
    cursor: pointer;
}
.right-sidebar-arrow {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    /* border-left: var(--sidebar-border); */
}
.left-sidebar-arrow {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    /* border-right: var(--sidebar-border); */
}
.sidebar-general {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    display: flex;
    flex-direction: column;
    /* width: 17.5%; */
    border-radius: 8px;
    margin-top: 8vh;
    margin-bottom: 8vh;
    background-color: rgba(250, 250, 250, 0.897);
    font-size: 11pt;
    box-shadow: var(--boxShadowFull);
    /* border-top: var(--sidebar-border); */
    /* border-bottom: var(--sidebar-border); */
}
.sidebar-general > .div {
    flex: 1 1;
}

.plexus-logo {
    /* width: fit-content !important;
    padding: 0.4em;
    font-size: 0.7em; */
}
#concepts-tab {
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
    padding: 0.4em;
    font-size: 0.6em;
}

.closePageButton {
    font-size: 1.4em;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-right: 1em;
    color: gray;
}
.closePageButton:hover {
    cursor: pointer;
}

.headerButton {
    font-size: var(--normal-bullet-font-size);
    /* color: var(--bullet-color); */
    color: gray;
    height: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px 1.5vw;
    margin: 2pt 4pt;
    /* align-self: flex-start; */
    /* background-color: var(--very-light-gray); */
    border-radius: calc(0.6 * var(--group-border-radius));
    box-shadow: var(--boxShadowSmall);
    /* width: 3em; */
}
.headerButton:hover,
.headerButton.on {
    cursor: pointer;
    color: var(--font-color);
    background-color: var(--very-light-color);
    box-shadow: var(--boxShadowSmall);
}
.headerButton:active {
    box-shadow: var(--boxShadowHover);
}
.headerButton > * > input {
    width: 2em !important;
}
.headerButton.focused > * > input {
    width: 50vw !important;
}
input {
    transition: width 0.25s ease-in-out !important;
}

.editor-container {
    max-width: 600pt;
    background-color: white;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* box-shadow: 0 0 5px rgb(232, 232, 232); */
    height: 100%;
    width: 100%;
    font-size: var(--normal-bullet-font-size);
    /* outline: 2px gainsboro solid; */
}
.note-title {
}

.linked-title {
    color: var(--deep-blue);
    /* color: var(--dark-gray); */
}

.referenceElement {
    color: black;
    /* text-decoration: underline; */
    text-decoration-thickness: 1px;

    text-underline-offset: 2px;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.12s ease-out, box-shadow 0.12s ease-out;
}

.referenceElement.repeats {
    color: var(--deep-blue);

    /* color: rgb(83, 0, 199); */

    /* color: rgb(0, 0, 159); */

    /* text-decoration: underline; */
    /* text-decoration-offset: 0; */
    text-underline-offset: 0px;

    text-decoration-thickness: 1px;
}
.referenceElement.unconfirmed {
    /* color: var(--deep-blue); */

    /* color: black; */
    /* font-weight: normal; */
}
.referenceElement.repeats:not(.unconfirmed) {
    /* background-color: rgb(238, 245, 255); */
    color: rgb(61, 0, 184);
    /* text-decoration: underline; */
    text-decoration-thickness: 0.15em;
    -webkit-text-decoration-color: rgb(221, 221, 255);
            text-decoration-color: rgb(221, 221, 255);

    /* background-color: rgb(233, 233, 255); */

    /* font-weight: bold; */
    /* font-weight: bold; */
}

.referenceElement.flare,
.referenceElement.clickable:hover {
    color: var(--electric-blue);
    cursor: pointer;
    background-color: var(--background-purple);
    /* box-shadow: 0 0 6px rgb(185, 188, 255); */
    text-decoration: none;
}
.referenceElement.title {
    cursor: default;
}

#entities-button-container {
    border-radius: 50%;
    width: 3.2em;
    height: 3.2em;
    margin: 0 1em;
    /* background-color: rgb(245, 245, 245); */
    display: flex;
    justify-content: center;
    align-items: center;
    outline-color: white;
    outline: 2px solid rgb(232, 232, 232);
    transition: outline-color 0.1s, background-color 0.1s;
}
#entities-button-container:hover {
    outline: 1px solid rgb(225, 225, 225);

    cursor: pointer;
}
#entities-button-container:active {
    outline-color: rgb(255, 209, 219);
    background-color: rgb(255, 218, 218);
}
#plexus-logo-for-button {
    width: 2.5em;
    height: 2.25em;
    padding: 0.1em;
    padding-bottom: 0.2em;
    padding-top: 0.05em;

    padding-right: 0.14em;
}

.stupid-button {
    font-size: small;
    padding: 0.2em 1.5%;
    border-radius: 0.3em;
    cursor: pointer;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background-color: rgb(243, 244, 255);
    box-shadow: 0 0 4px rgb(211, 202, 255);
    color: rgb(20, 20, 255);
    transition: width 0.1s;
    align-self: center;
    margin-right: 2em;
}
.stupid-button:active {
    color: var(--electric-purple);
    outline-color: var(--background-purple);
    box-shadow: none;
    background-color: var(--background-purple);
}

.references {
    /* margin-top: 1em; */
    color: rgb(103, 103, 103);
    /* font-size: 15px; */
    /* box-shadow: 0 0 5px lightgray; */
}
.references-title {
    /* font-weight: bold; */
    font-size: large;
    cursor: default;
    padding: 0.4em 0;
    /* margin-top: 5px; */
    cursor: pointer;
    /* color: rgb(176, 176, 176); */
}
.references-title.empty {
    color: gainsboro;
}
.reference-item {
    /* margin-left: 0.4em; */
    margin: 0.2em 0;
    line-height: 1.3;
    padding: 0.5em;
    border-radius: 0.3em;
    /* background-color: rgba(128, 128, 128, 0.057); */
    display: flex;
    /* border-left: 2px solid gainsboro; */
    /* font-size: 14px; */
}
.reference-item-title {
    color: var(--deep-blue);
    cursor: pointer;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 5px;
    margin: 0.2em;
    /* font-size: 1.1em; */
}
.reference-item-title:hover {
    opacity: 1;

    color: var(--electric-purple);
    background-color: var(--background-purple);
}
.reference-content {
    /* margin-left: 0.4em; */
    /* padding: 0.6em; */
    border-radius: 8px;
}

div {
    border-radius: 0.5em;
}
.paste-button {
    color: rgb(187, 187, 187);
    margin: 0 0.5em;
    /* padding: 0.1em; */
    /* transform: rotate(-90deg); */
}

.paste-button:active {
    background-color: rgba(128, 128, 128, 0.153);
    color: var(--electric-purple);
}

.references-from-a-page {
    /* color: red; */
    margin: 0.3em 0 0em;
    padding: 0.3em;
    border-radius: 0.5em;
    background-color: rgb(244, 244, 244);
    /* outline: 2px rgb(239, 239, 255) solid; */
    cursor: pointer;
}
.references-from-a-page.clear {
    /* background-color: transparent; */
}

.toggle-and-title {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.referencesCaret {
    margin: 0 0.1em;
}

.interactive-references-input {
    display: inline-flex;
    /* text-decoration: underline; */
    cursor: text;
    border-radius: 3px;
    /* text-underline-offset: 3px; */
    /* text-decoration-color: rgb(223, 223, 223); */
}
/* .interactive-references-input:hover {
    background-color: gainsboro;
    color: black;
} */

.toolbar {
    text-align: center;
    position: absolute;
    z-index: 1;
    top: -10000px;
    left: -10000px;
    /* border-radius: 1em !important; */
    /* margin-top: -6px; */
    opacity: 0;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 4px;
    padding-right: 4px;
    /* border: solid 0.2ex; */
    /* box-shadow: 0px 0px 10px 0px rgb(201, 201, 201); */
    /* border-color: var(--very-light-gray); */
    /* background-color: white; */
    background-color: transparent;
    /* var(--very-light-gray); */
    align-items: center;
    border-radius: 30%;
    transition: opacity 0.75s;
    display: inline-block;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    /* transition: top 0.1s; */
}

.active {
    cursor: pointer;
    /* color: ${reversed
        ? active
        ? 'white'
        : '#aaa'
        : active
        ? 'black'
        : '#ccc'}; */
}

.apple {
    margin: 30px -20px 0;
}

.banana {
    font-size: 14px;
    padding: 5px 20px;
    color: #404040;
    border-top: 2px solid #eeeeee;
    background: #f8f8f8;
}

.carrot {
    color: #404040;
    font: 12px monospace;
    white-space: pre-wrap;
    padding: 10px 20px;
    /* div {
        margin: 0 0 0.5em;
    } */
}
.selection-toolbar-button {
    border-radius: 30%;
    width: 3.6em;
    height: 3.6em;
    /* background-color: rgb(230, 230, 230); */
    display: flex !important;
    align-items: center;
    justify-content: center;
}
#conceptifyIcon {
    border-radius: 50%;
    width: 3.2em;
    height: 3.2em;
    transition: height width 0.5s;
    background-color: white;
    border: 3px solid var(--background-purple);
    box-shadow: 0 0 12px 6px rgb(195, 198, 225);
    transition: background-color 0.3s;
    transition: border 0.3s;
}

.format-button-text {
    font-size: 14px;
}
#conceptifyIcon:active {
    background-color: var(--sky-electric-blue);
    outline: none !important;
    border: none !important;
    border-color: transparent;
}
#conceptifyIcon.expand {
    width: 2.5em;
    height: 2.5em;
    background-color: var(--electric-blue);
}

.page-close-button {
    color: gray;
    margin: 0 1em 0 0;
    cursor: pointer;
}

.slate-paragraph.title {
    font-size: 1.4em;
}
.slate-paragraph {
    border-radius: '5px';
    margin: 0.4em 0;
}

.pinned {
    /* background-color: rgb(244, 244, 244); */
    /* padding: 0.3em;
    margin: 0.6em; */
    /* background-color: transparent; */
    box-shadow: 0 0 2px rgb(191, 191, 191);
    margin: 1em;
}
.pinned-note-title {
    font-size: 0.9em;
    color: gray;
    align-self: left;
}

.header-and-pages {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
}

.the-two-panels {
    display: flex;
    flex-direction: row;
    height: 100%;
    /* justify-content: space-evenly; */
}

#seeSettingsIcon {
    transition: -webkit-transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
}
#seeSettingsButton.on > #seeSettingsIcon {
    -webkit-transform: rotate(100deg);
            transform: rotate(100deg);
}
#seeSettingsButton {
    margin: 1vh;
    position: absolute;
    right: 0;
    top: 0;
}

.center {
    display: flex;
    align-items: center;
    flex-direction: column;
}
.signInView {
    margin: 12%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-width: 50%;
    font-family: 'Optima';
}
.signInView > .form-group {
    width: 100%;
}
.form-group > * {
    border-radius: 0.4em;

    /* padding: 1em; */
}
.loginInput {
    font-family: 'Optima';
    font-size: var(--normal-bullet-font-size);
    box-shadow: none;
    border: none;
    outline: none;
    outline: 1px rgb(231, 216, 255) solid;

    /* border-bottom: solid 2px var(--medium-color); */
    /* border-left: solid 2px var(--medium-color); */

    /* outline: 2px var(--dark-color); */
    /* padding: 10pt; */
}
.loginInput:focus {
    border: none;
    outline: 1px lightblue solid;
    /* border-left: solid 2px transparent; */
}
.enterPlexus {
    background-color: rgb(150, 162, 247);
    font-weight: bold;
    color: white;
    min-width: 25%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    min-height: 30px;
    font-size: 1.5em;
    outline: none;
    border: none;
    border-radius: 0.4em;
}
.enterPlexus:active {
    background-color: var(--electric-blue) !important;
}
.reset-password-link {
    text-decoration: underline;
    cursor: pointer;
}

.sign-in-view-container {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
}

/*Starting page*/
.startingPage {
    display: flex;
    /* padding-top: 4vh; */
    justify-content: top;
    flex-direction: column;
    align-items: center;
    color: rgb(252, 84, 84);
    /* background-color: rgb(252, 252, 252) !important; */
    text-align: center;
    font-size: 24px;
    font-family: var(--the-font-family) !important;
    background-color: white;
    height: 100%;
    vertical-align: center;
}

#loader {
    width: 40px;
    transition: width 0.75s ease;
}
#loader.bigger {
    width: 12vh;
    /* height: 49px; */
    /* float: left; */
}
.link {
    color: var(--gray-color);
    padding: 4px;
    justify-self: end;
    letter-spacing: 1px;
    font-size: 0.8em;
    color: rgb(175, 175, 175);
    /* color: rgb(170, 0, 0); */
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 0.2em 0.4em;
    border-radius: 0.45em;
    transition: box-shadow 0.2s;
    font-weight: bold;
    transition: background-color 0.1s ease-out;
    text-decoration: none;
}
.link:hover {
    /* background-color: rgb(255, 225, 235); */
    color: rgb(109, 109, 109);
    text-underline-offset: 2px;

    /* text-decoration: none; */

    /* color: white; */

    /* color: var(--font-color); */
}
.waitlistLink {
    /* font-size: 14pt; */
    /* color: var(--font-color); */
    color: white !important;
    text-decoration: none !important;
}
.everythingElse {
    opacity: 0;
    transition: opacity 2.4s;
    width: 100%;
    min-width: 200pt;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.startPageLinks {
    /* padding-top: 1vh; */
    /* padding-top: 1vh; */
    transition: opacity 3s;
    /* width: fit-content; */
    display: flex;
    flex-direction: column;
    margin: 0;
    align-items: center;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-bottom: 4em;
    /* justify-content: flex-start; */
}
.everythingElse.show {
    opacity: 1;
    /* height: 150px; */
    /* height: 100%; */
}
.brand {
    /* margin: 5%; */
    /* width: 100%; */
    color: rgb(256, 100, 100);
    /* padding: 1%; */
    letter-spacing: 1px;
}
.plexusText {
    font-weight: bold;
    font-size: 5.5vh;
    color: var(--plex-color);
    margin-bottom: -100px;
    letter-spacing: 3px;
}
.tagline {
    font-size: 3.5vh;
    /* font-weight: bold; */
    /* font-style: italic; */
    color: rgb(256, 100, 100);
}
.centered {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.oneLiner {
    padding: 2vh;
    font-size: 2.5vh;
    height: 3em;
    /* font-style: italic; */
    color: rgb(109, 109, 109);

    /* color: rgb(160, 160, 160); */
    line-height: 1.8em;
    /* letter-spacing: 1px; */
}
.importantPhrase {
    text-underline-offset: 0.2em;
    text-decoration: underline;
    -webkit-text-decoration-color: gainsboro;
            text-decoration-color: gainsboro;
    text-decoration-thickness: 1px;
}
.startingPage-topBanner {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    padding: 0.5em 1em;
    font-size: 2.3em;
    font-family: 'Optima';
}
.other-links {
    display: flex;
    flex-direction: row;

    font-size: 0.7em;
    margin: 1em;
    width: 100%;
    opacity: 0.6em;
    justify-content: center;
}
.other-links > * {
    padding: 0 0.7em;
    text-decoration: none;
    color: gray;
    font-style: italic;
}

:root {
    --boxShadowBig: 0px 0px 10px 0px var(--high-color);
    --boxShadowHuge: 0px 0px 20px 0px var(--plex-color);
    --boxShadowRegistering: 0px 0px 6px 2px rgb(231, 232, 255);
}
.emailSignupContainer {
    box-shadow: var(--boxShadowFull);
    border-radius: 0.3em;
    padding: 0.2em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    align-items: center;
    margin: 1em 0;
    background-color: white;
    grid-gap: 0.2em;
    gap: 0.2em;
    flex-wrap: wrap;
}
/* .emailSignupContainer > * + .emailSignupContainer > * {
    padding-right: 1em;
} */

.emailSignupContainer:focus-within {
    box-shadow: 0px 0px 6px 2px rgb(231, 232, 255);
    box-shadow: var(--boxShadowRegistering);
    outline: rgb(215, 240, 255) 3px solid;
}
.emailSignupContainer > .form-control {
    color: black !important;
    border: none;
    box-shadow: none !important;
    font-size: 15px;
    padding-right: 0 !important;
    /* margin-right: 0.5em !important; */
    padding-left: 0.5em !important;
    outline: none !important;
}
.get-early-access-button {
    /* letter-spacing: 0.px; */
    font-size: 0.8em;
    background-color: rgb(255, 169, 176);
    /* var(--plex-color); */
    color: white;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-weight: bold;
    padding: 0.4em 0.6em;
    border-radius: 0.5em;
    box-shadow: var(--boxShadowFull);
    transition: box-shadow 0.2s;
    text-decoration: none;
    /* transition: padding 0.1s; */
}
.get-early-access-button.authorized {
    background-color: rgb(255, 110, 120);
}
.get-early-access-button:hover {
    cursor: pointer;
    box-shadow: 0px 0px 10px 0px var(--high-color);
    box-shadow: var(--boxShadowBig);
}
.get-early-access-button:active {
    box-shadow: 0px 0px 20px 0px var(--plex-color);
    box-shadow: var(--boxShadowHuge);
}
.smallerButton {
    /* transition: padding 0.1s; */
}
.email-text-input {
    margin: 0 0.2em;
    outline: none !important;
    text-align: left;
    font-size: var(--normal-bullet-font-size);
    color: black;
    overflow: none;
    transition: width 0.2s;
}
.email-text-input:hover {
    cursor: text;
}
[contenteditable='true']:empty:before {
    content: attr(placeholder);
    /* display: block; */
    color: #aaa;
}
.email-text-input.authorized {
    /* color: rgb(202, 0, 17); */
    /* font-weight: bold; */
    /* text-decoration: underline gray 1px; */
}

#referlistemail {
    color: black;
    /* font-size: 0.8em; */
    /* flex: 3; */
    width: 10em;
    min-width: 80pt;
    border: none;
}
#referlistbutton {
    color: black;
    font-size: 0.7em;
    min-width: 7em;

    /* flex: 2; */
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border: none;
}

