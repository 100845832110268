/*Starting page*/
.startingPage {
    display: flex;
    /* padding-top: 4vh; */
    justify-content: top;
    flex-direction: column;
    align-items: center;
    color: rgb(252, 84, 84);
    /* background-color: rgb(252, 252, 252) !important; */
    text-align: center;
    font-size: 24px;
    font-family: var(--the-font-family) !important;
    background-color: white;
    height: 100%;
    vertical-align: center;
}

#loader {
    width: 40px;
    transition: width 0.75s ease;
}
#loader.bigger {
    width: 12vh;
    /* height: 49px; */
    /* float: left; */
}
.link {
    color: var(--gray-color);
    padding: 4px;
    justify-self: end;
    letter-spacing: 1px;
    font-size: 0.8em;
    color: rgb(175, 175, 175);
    /* color: rgb(170, 0, 0); */
    height: fit-content;
    width: fit-content;
    padding: 0.2em 0.4em;
    border-radius: 0.45em;
    transition: box-shadow 0.2s;
    font-weight: bold;
    transition: background-color 0.1s ease-out;
    text-decoration: none;
}
.link:hover {
    /* background-color: rgb(255, 225, 235); */
    color: rgb(109, 109, 109);
    text-underline-offset: 2px;

    /* text-decoration: none; */

    /* color: white; */

    /* color: var(--font-color); */
}
.waitlistLink {
    /* font-size: 14pt; */
    /* color: var(--font-color); */
    color: white !important;
    text-decoration: none !important;
}
.everythingElse {
    opacity: 0;
    transition: opacity 2.4s;
    width: 100%;
    min-width: 200pt;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.startPageLinks {
    /* padding-top: 1vh; */
    /* padding-top: 1vh; */
    transition: opacity 3s;
    /* width: fit-content; */
    display: flex;
    flex-direction: column;
    margin: 0;
    align-items: center;
    height: fit-content;
    margin-bottom: 4em;
    /* justify-content: flex-start; */
}
.everythingElse.show {
    opacity: 1;
    /* height: 150px; */
    /* height: 100%; */
}
.brand {
    /* margin: 5%; */
    /* width: 100%; */
    color: rgb(256, 100, 100);
    /* padding: 1%; */
    letter-spacing: 1px;
}
.plexusText {
    font-weight: bold;
    font-size: 5.5vh;
    color: var(--plex-color);
    margin-bottom: -100px;
    letter-spacing: 3px;
}
.tagline {
    font-size: 3.5vh;
    /* font-weight: bold; */
    /* font-style: italic; */
    color: rgb(256, 100, 100);
}
.centered {
    width: fit-content;
}
.oneLiner {
    padding: 2vh;
    font-size: 2.5vh;
    height: 3em;
    /* font-style: italic; */
    color: rgb(109, 109, 109);

    /* color: rgb(160, 160, 160); */
    line-height: 1.8em;
    /* letter-spacing: 1px; */
}
.importantPhrase {
    text-underline-offset: 0.2em;
    text-decoration: underline;
    text-decoration-color: gainsboro;
    text-decoration-thickness: 1px;
}
.startingPage-topBanner {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    padding: 0.5em 1em;
    font-size: 2.3em;
    font-family: 'Optima';
}
.other-links {
    display: flex;
    flex-direction: row;

    font-size: 0.7em;
    margin: 1em;
    width: 100%;
    opacity: 0.6em;
    justify-content: center;
}
.other-links > * {
    padding: 0 0.7em;
    text-decoration: none;
    color: gray;
    font-style: italic;
}
