.toolbar {
    text-align: center;
    position: absolute;
    z-index: 1;
    top: -10000px;
    left: -10000px;
    /* border-radius: 1em !important; */
    /* margin-top: -6px; */
    opacity: 0;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 4px;
    padding-right: 4px;
    /* border: solid 0.2ex; */
    /* box-shadow: 0px 0px 10px 0px rgb(201, 201, 201); */
    /* border-color: var(--very-light-gray); */
    /* background-color: white; */
    background-color: transparent;
    /* var(--very-light-gray); */
    align-items: center;
    border-radius: 30%;
    transition: opacity 0.75s;
    display: inline-block;
    width: fit-content;
    height: fit-content;
    /* transition: top 0.1s; */
}

.active {
    cursor: pointer;
    /* color: ${reversed
        ? active
        ? 'white'
        : '#aaa'
        : active
        ? 'black'
        : '#ccc'}; */
}

.apple {
    margin: 30px -20px 0;
}

.banana {
    font-size: 14px;
    padding: 5px 20px;
    color: #404040;
    border-top: 2px solid #eeeeee;
    background: #f8f8f8;
}

.carrot {
    color: #404040;
    font: 12px monospace;
    white-space: pre-wrap;
    padding: 10px 20px;
    /* div {
        margin: 0 0 0.5em;
    } */
}
.selection-toolbar-button {
    border-radius: 30%;
    width: 3.6em;
    height: 3.6em;
    /* background-color: rgb(230, 230, 230); */
    display: flex !important;
    align-items: center;
    justify-content: center;
}
#conceptifyIcon {
    border-radius: 50%;
    width: 3.2em;
    height: 3.2em;
    transition: height width 0.5s;
    background-color: white;
    border: 3px solid var(--background-purple);
    box-shadow: 0 0 12px 6px rgb(195, 198, 225);
    transition: background-color 0.3s;
    transition: border 0.3s;
}

.format-button-text {
    font-size: 14px;
}
#conceptifyIcon:active {
    background-color: var(--sky-electric-blue);
    outline: none !important;
    border: none !important;
    border-color: transparent;
}
#conceptifyIcon.expand {
    width: 2.5em;
    height: 2.5em;
    background-color: var(--electric-blue);
}
