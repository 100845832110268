.Plexus-container {
    height: 100vh;
    overflow: visible;

    /* display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center; */
}
html {
    /* overflow:auto; */
    overflow: hidden;
}
html::-webkit-scrollbar {
    display: none;
}
body {
    width: 100%;
    /* height: 100%; */
    /* background-color: 'lightblue'; */
    overflow: visible;
}
:root {
    --purpley-lighter: rgb(156, 0, 127);
    --purpley: rgb(147, 0, 88);
    --darker-than-font-color: rgb(73, 0, 0);
    --electric-plex-color: rgb(198, 0, 86);
    --font-color: rgb(148, 25, 41);
    --grayer-font-color: rgb(189, 1, 1);
    --darker-color: rgb(200, 64, 64);
    --origin-mark-color: rgb(109, 50, 50);
    --normal-bullet-font-size: 18px;
    --dark-color: rgb(233, 108, 108);
    --plex-color: #fb7272;
    --high-color: rgb(255, 152, 152);
    --notch-color: rgb(255, 188, 188);
    --medium-color: rgb(255, 197, 197);
    --light-medium-color: rgb(254, 207, 207);
    --background-color: rgb(255, 219, 219);
    --light-background-color: rgb(255, 234, 234);
    --the-font-family: Optima, system;
    /* --very-light-background-color: rgb(255, 233, 233); */

    --light-color: rgba(255, 0, 0, 0.075);
    --very-light-color: rgb(255, 246, 246);
    /* --super-light-color: rgb(255, 248, 250); */

    --supremely-light-color: rgb(255, 250, 250);

    --dark-gray: rgb(97, 97, 97);
    --medium-gray-color: rgb(161, 160, 160);
    --gray-color: rgb(176, 176, 176);
    --light-gray: rgb(199, 199, 199);
    --more-light-gray: rgb(226, 226, 226);
    --very-light-gray: rgb(241, 241, 241);
    --very-very-light-gray: rgb(245, 245, 245);
    --bullet-fade-color: rgb(233, 224, 224);
    --indent: 42px;
    --default-font-size-transition: font-size ease 0.15s;
    --font-weight: 600;
    --sidebar-border: 2px solid rgb(241, 241, 241);
    --scrollbar-border: 3px solid rgb(228, 228, 228);
    --group-border-radius: 0.6em;
    --groupBoxShadowBackgroundColor: rgb(255, 225, 225);
    --groupBoxShadowColor: rgb(224, 224, 224);
    --groupBoxShadowBlurRadius: 4px;
    --boxShadowTentative: 0px 0px 4px 0px rgb(229, 229, 229);
    --boxShadowFull: 0px 0px var(--groupBoxShadowBlurRadius) 0px var(--groupBoxShadowColor);
    --boxShadowHover: 0px 0px var(--groupBoxShadowBlurRadius) 1px var(--groupBoxShadowColor);
    --boxShadowActive: 0px 0px calc(1 * var(--groupBoxShadowBlurRadius)) 2px
        var(--groupBoxShadowColor);
    --boxShadowSmall: 0px 0px calc(0.6 * var(--groupBoxShadowBlurRadius)) 0px
        var(--groupBoxShadowColor);

    --boxShadowFullWithBackground: 0px 0px calc(1 * var(--groupBoxShadowBlurRadius)) 0px
        var(--groupBoxShadowBackgroundColor);

    --black-font-color: #212529;

    --electric-purple: rgb(55, 0, 255);
    --deep-blue: rgb(0, 82, 205);
    --electric-blue: rgb(67, 29, 255);
    --sky-electric-blue: rgb(115, 143, 255);

    --light-aqua: rgb(122, 227, 202);

    --default-purple: rgb(43, 43, 184);
    --light-purple: rgb(208, 221, 255);

    --background-purple: rgb(232, 232, 255);
    --grayed-purple: rgba(63, 47, 123, 0.6);
}

#root {
    overflow: visible;
}
#whole-thing {
    /* margin: 0px 40px 0px 40px; */
    overflow: visible;
    font-family: var(--the-font-family);
    /* Optima, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', */
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    letter-spacing: 0pt;
    /* background-color: rgb(253, 25, 253); */
}

.button {
    font-family: var(--the-font-family);
}
.loginBtn {
    font-size: 10px;
    padding: 8px;
    margin: 0px;
    border-width: 0px;
    line-height: normal;
    height: auto;
    /* padding: 5px; */
    /* height: 36px; */
    /* text-align: right; */
    /* display: flex; */
}

/* Title bullet */
.titleBullet {
    /* border-bottom: 2px solid var(--background-color); */
    /* text-align: center; */
    display: flex;
    overflow-wrap: normal;
    /* font-weight: var(--font-weight); */
    font-weight: bold;
    font-size: calc(2.3 * var(--normal-bullet-font-size));
    overflow: visible;
    transition: contents ease 0.5s;
    /* position: relative; */
    margin: 0px 0px calc(0.5 * var(--normal-bullet-font-size)) 0px;
    /* width: 100%; */
    /* text-decoration: underline; */
    /* text-align: center; */
    /* width: 50px; */
}
.rotate-up-point {
    transform: rotate(30deg);
    transition: transform 0.2s;
}
.rotate-up {
    transform: rotate(33deg);
    transition: transform 0.5s;
}
.rotate-down {
    transform: rotate(328deg);
    transition: transform 0.5s;
}

#title {
    /* font-family: var(--the-font-family); */
    /* top: 100px; */
}

.titleWrapper {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
}

.addSymbol {
    position: relative;
    height: 0ex;
    font-size: 2.4ex;
    padding: 4px;
    /* text-align: center; */
    padding-top: 0px;
    color: var(--medium-color);
    z-index: 1;
}

.addSymbol:hover {
    color: var(--plex-color);
    cursor: pointer;
}

#cy-container {
    /* margin: 10px; */
    display: flex;
    /* float: right; */
    align-self: flex-start;
    text-align: center;
    /* justify-self: flex-end; */
    margin-right: 3em;
    margin-bottom: 10em;
    flex-grow: 100;
    /* color: red; */
    /* width: 100px; */
    width: 20%;
    height: 72vh;
    /* width: 200px; */
    /* height: 200px; */
    /* margin: 4em; */
}
#cy-container > * {
    display: flex;
}
/* 
  @media only screen and (max-width: 100vh) {
    #cy-container {
        display: none;
    }
  } */

/* BULLETS */
.subBullets {
    display: flex;
    flex-direction: column;
    text-align: left;
}
/* .neighbors::-webkit-scrollbar { width: 1 !important } */

.neighbors {
    scrollbar-color: light;
    justify-content: flex-start;
    /* max-width: 40%; */
    width: fit-content;
    /* display: flex; */
    /* float: left; */
    /* display: inline-block; */

    /* position: visible; */
}
/* .neighbors > *{
  scroll-snap-align: end;
} */
/* .neighbors::after {
  scroll-snap-align: end;
  display: block;
} */
.fullHeight {
    overflow-y: auto;
    scroll-snap-type: y proximity;
    scroll-padding-bottom: 15px;
    /* scroll-snap-points-y: repeat(100vh); */
    height: 70vh;
}
.fullHeight > * {
    scroll-snap-align: start;
}
.nicky {
    overflow-y: visible;
    display: flex;
    flex-direction: column;
}
/* More bullets */
.indented {
    margin-left: var(--indent);
    display: flex;
    flex-direction: column;
}

/* not sure why this works but the above does not */
input {
    font-family: var(--the-font-family);
    /* font-size: calc(var(--normal-bullet-font-size)) !important; */
    /* width: 60vh !important; */
}

input [type='text'] {
    font-family: var(--the-font-family) !important;
}

/* input[type='text']:not(.browser-default):focus {
    border-bottom: solid thin var(--plex-color) !important;
    box-shadow: 0 1px 0 0 var(--plex-color) !important;
} */

.levelOneBullet {
    padding-bottom: 1.4em;
}
.normalBullet {
    /* text-align: center; */
    /* color: var(--plex-color);  */
    /* color: rgb(118, 118, 118); */
    /* font-family: var(--the-font-family); */
    /* font-size: 2ex; */
    border-radius: 8px;
    display: block;
    /* padding-top: 8px; */
    margin-left: 32px;
    /* margin-bottom: 12px; */
    /* padding-bottom: calc(.5 * var(--normal-bullet-font-size)); */
    /* height: 40px; */
    /* align-items: center; */
    transition: margin-bottom ease 0.14s;
    transition: opacity ease 0.5s;
    /* width: fit-content; */
    /* display: flex; */
}
/* .point:hover .normalBullet{
  color: var(--plex-color);
} */
.gray {
    color: var(--gray-color);
    font-weight: lighter;
    /* font-size: 1.5ex; */
}

/* .square{
  background-color: transparent;
  width: calc(0.8*var(--normal-bullet-font-size));
  height: calc(0.8*var(--normal-bullet-font-size));
  border: solid .2ex;
  transition: width .1s, height .1s;
} */

.square {
    cursor: pointer;
}
.square:hover:not(.full) {
    width: 2ex;
    height: 2ex;
    background-color: var(--background-color);
}

.internalBullet {
    /* text-align: center; */
    display: flex;
    flex-direction: row;
    align-items: center;
    /* width: fit-content; */
    /* margin-left:  */
    /* vertical-align: middle; */
    /* padding: 4px; */
}
/* .internalBullet{
} */
/* .normalBullet:hover{
  padding: 0px;
  margin: 0px;
} */
/* .normalBullet:hover{cursor: pointer;} */
.concept:hover {
    /* background-color: rgb(255, 225, 225); */
    /* color: var(--plex-color); */
    cursor: pointer;
}
.normal-concept-hover {
    margin-bottom: 0;
}
.concept-hover {
    /* background-color: rgb(255, 225, 225); */
    /* color: var(--plex-color); */
    cursor: pointer;
    font-size: 2.5ex;
}
.close {
    font-size: 0.5em;
    visibility: hidden;
    border-radius: 6px;
    color: var(--plex-color);
}
.close:hover {
    cursor: pointer;
    /* background-color:  rgb(255, 219, 219); */
}
.predicate:hover .close {
    visibility: visible;
    background-color: rgb(255, 173, 173);
}
.separator {
    padding: 4px;
    padding-left: 0px;
}
.stack {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.buttonNew {
    background-color: var(--medium-color);
    margin: 0.5em 0em;
    padding: 0 1em;
    /* padding-bottom: 5px; */
    /* height: 2.5em; */
    border: 0;
    vertical-align: middle;
    /* padding: 8px; */
    /* font-weight: bold; */
}
.buttonBar {
    visibility: hidden;
    display: flex;
    flex-direction: row;
    /* justify-content: :  */
    justify-content: center;
    margin-left: 16px;
    color: var(--medium-color);
}
.buttonBar > * {
    /* display: fle; */
    margin: 0px 0px;
    /* align-self: center; */

    padding: 4px 8px;
    cursor: pointer;
    transform: rotate(0deg);
    display: block;
    transition: transform 0.2s ease 0.2s;
    transition: var(--default-font-size-transition);
    transform: rotate(0deg);
    display: flex;
    flex-direction: row;

    /* margin: 0px 20px; */
}
/* .editNode{
  padding: 0px 0px;
  margin-bottom: 4px;
} */
.nodify {
    align-self: center;
}
.addBeforeEdge {
    padding-right: 0px;
    margin-right: 0px;
}
.addAfterEdge {
    padding-left: 0px;
    margin-left: 0px;
}
.buttonBar > *:hover {
    color: var(--plex-color);
    /* transform: (90deg); */
    /* font-size: 3ex; */
}
.editNode:hover {
    color: var(--medium-color);
}
/* .addAfterEdge{
  transform: rotate(-83deg);
} */
/* .addBeforeEdge{
  transform: rotate(0deg);
}  */
.relatSymbol {
    margin-right: 0px;
    padding-right: 0px;
    padding: 0px 4px;
    font-size: 1.8ex;
}
.editNode {
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 0px;
    padding-right: 0px;
    font-size: 4ex;
}
.relatSymbol:hover {
    /* font-weight: var(--font-weight); */
    color: var(--plex-color);
}
.editNode:hover {
    /* font-size: 4.4ex; */
}
.internalBullet:hover .buttonBar {
    visibility: visible;
}
.showMore {
    /* padding: 4px; */
    transition: transform 0.2s ease;
}
.showMore:hover {
    transform: rotate(90deg);
    transition: transform 0.2s ease;
}

#xOut {
    /* float: right; */
    /* vertical-align: middle; */
    /* align-self: center; */
    font-size: 1.2ex;
    /* border-radius: 6px; */
    visibility: hidden;
}
.internalBullet:hover #xOut {
    visibility: visible;
}
#xOut:hover {
    /* background-color: rgb(255, 173, 173); 
  cursor: pointer;
  background-color:  rgb(255, 219, 219); */
    /* font-size: 1.8ex; */
}
.predicate:hover {
    cursor: text;
}
.selected-bullet,
.selected-bullet:hover {
    /* color: white; */
    /* background-color: var(--plex-color); */
    border-left: 6px solid var(--plex-color);
}
.concept,
.contentEditable {
    padding: 4px;
    padding-right: 0px;
    padding-left: 0px;
    /* padding-bottom: 0px; */

    /* -webkit-animation-duration: 10s;animation-duration: 10s;
  -webkit-animation-fill-mode: both;animation-fill-mode: both; */
    transition: width ease 0.5s;
}
.fadeOut {
    /* transform: translateX(220px); */
    width: 400px;
    opacity: 0;
}
/* @-webkit-keyframes fadeOut {
  0% {opacity: 1;}
  100% {opacity: 0;}
}

@keyframes fadeOut {
  0% {opacity: 1;}
  100% {opacity: 0; width: 400px;}
}
.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
} */
.concept {
    font-weight: 600;
    border-radius: 6px;
    padding: 0.3em 0;
    transition: font-size ease 0.15s;
    /* color: var(--plex-color); */
}
.bold {
    font-weight: var(--font-weight);
}
.temp {
    /* margin-top: 40px; */
}
.predicate {
    /* font-style: italic; */
    /* text-decoration: underline; */
    /* font-size: .8em; */
    /* padding-right: 5px; */
    outline: 0;
    /* color:navy; */
    /* font-size: 1.8ex; */
    /* var(--dark-color); */
}
.contentEditable {
    /* height: calc(var(--normal-bullet-font-size)); */
    /* padding: 0px; */
}
.showSuggestions {
    justify-self: center;
    padding: 4px;
    width: calc(0.6 * var(--normal-bullet-font-size));
    height: calc(0.6 * var(--normal-bullet-font-size));
    border-radius: 0px;
    transition: width 0.1s, height 0.1s;
    background-color: transparent;
    outline: solid 0.2ex var(--plex-color);
    outline-offset: 1px;
}
.showSuggestions:hover {
    cursor: pointer;
}
.suggestions-hover {
    background-color: lightgray;
}

/* .showSuggestions:hover{
 width: calc(.7* var(--normal-bullet-font-size));
  height: calc(.7* var(--normal-bullet-font-size));
} */
.full {
    /* max-height: 40px; */
    background-color: var(--gray-color);
    /* var(--plex-color); */
}
.smaller {
    font-size: 1.4ex;
}

/* .gray>*{
  background-color: none;
} */

/* Record kept on top left */
.pastOne {
    /* margin: 4px; */
    margin-left: 8px;
    border-radius: 4px;
    padding: 4px 0px;
    vertical-align: text-top;
    transition: font-size ease 0.15s;
}
.pastOne:hover {
    font-size: 2.3ex;
}
#lastFive {
    margin: 8px;
    cursor: pointer;
    height: 16px;
    font-size: 1.8ex;
}
#saveSignal {
    font-size: 1.2ex;
    text-align: center;
    align-self: center;
    font-style: italic;
    margin-right: 2ex;
    /* border-radius: 1.4ex;
  border: solid 1px; */
}
#saveLoader {
    width: calc(1.2 * var(--normal-bullet-font-size));
}
.pumpingLoader {
    width: 80pt;
    margin-left: 8pt;
    /* align-self: center; */
}

/* Input Bars */
#addingBar {
    margin-top: 0px;
    font-size: 56px;
    display: flex;
    align-items: center;
    height: 50px;
}
.edgeSymbol:hover .internalSymbol {
    display: none;
}
.nodeSymbol:hover .internalSymbol {
    display: none;
}
.edgeSymbol {
    margin-left: 32px;
}
.edgeSymbol:hover:before {
    content: '→';
}
.nodeSymbol:hover:before {
    content: '○';
}
/* #addingBar:hover:before{
  content: '→'
} */
.nodeSymbol {
    font-size: 100px;
    margin-bottom: 10px;
    width: 100px;
}
.titleBullet:focus,
.predicate:focus {
    outline: 0;
}
#inputBars {
    margin-left: 32px;
    display: flex;
    color: var(--plex-color);
    /* width: 100%; */
    /* height: 80px; */
}
.bar {
    /* width: 40vh; */
    /* color: var(--plex-color); */
    margin: 8px 0px 0px 0px;
    /* position: fixed; */
    /* bottom: 7%; */
    display: flex;
    outline: none;
    /* justify-content: space-between; */
    align-items: baseline;

    text-align: center;
    /* border-width: 0 0 2px; */
    /* height: 65px; */
    /* width: 40vh; */
}
.bar > * {
    /* width: 100%; */
    /* width: 30vh; */
    /* margin-right: 24px; */
    outline: 0px;
}

.innerBar {
    /* width: 36%;  */
    /* float:right; */
    display: inline-block;
    margin: 1em 6em;
}
/* .innerBar>*, .innerBar>*>*{
  width: 100%;
}  */
#input-box-for-searching {
    display: inline;
    /* width: 100%; */
}

.inputBar {
    outline: none;
    border-width: 0;
    border-bottom-width: 'thin';
    /* min-width: 15vh; */
    /* display: flex;
  flex-direction: column; */
    /* align-items: baseline; */
    /* height: 8ex; */
    /* width: 45vh;
  margin: 0px 32px; */
    /* width: 300px; */
}
.nounBar > * > * > *,
.nounBar > * > * > * > * {
    font-weight: var(--font-weight);
}

/* #parent {
  list-style: none;
  width: 100%;
  height: 90px;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
} */
#reverseButton {
    align-self: center;
    /* visibility: hidden; */
    width: var(--normal-bullet-font-size);
    transition: visibility 0.1s, font-size 0.14s;
    border-radius: 8px;
}
#inputBars:hover #reverseButton {
    visibility: visible;
}
#reverseButton:hover {
    cursor: pointer;
}
#prepSymbol,
#nounSymbol,
.point {
    align-self: center;
}
.symbol {
    font-size: calc(0.8 * var(--normal-bullet-font-size));
    cursor: pointer;
    transition: font-size 0.14s, width 0.14s;
    color: rgb(255, 225, 225);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 10px;
    /* padding: 10px; */
    /* font-size: 100px; */
}
.symbol > *:hover {
    color: var(--plex-color);
    /* padding: 2px; */
}
.symbol-hover {
    color: var(--plex-color);
    /* font-size: var(--normal-bullet-font-size); */
}

#searchSymbol {
    width: 25px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.search-symbol-hover {
    width: 40px;
}
.option {
    font-size: 2.2ex;
    color: var(--dark-color);
    /* text-align: center; */
}
.small {
    /* font-size: 1.5ex; */
    /* color: var(--dark-color); */
    /* background-color: var(--plex-color); */
    overflow-wrap: wrap;
}
.hidden {
    visibility: hidden;
}
.sm {
    font-size: 12px;
    background-color: var(--gray-color);
    /* padding: 4px; */
}
.sm:hover {
    background-color: var(--plex-color);
}

#brand {
    /* text-align: center; */
    color: var(--plex-color);
    font-family: var(--the-font-family);
    font-style: italic;
    font-size: 32px;
    /* text-align: center; */
}
.indicators {
    display: flex;
    /* justify-content: space-evenly; */
    align-items: center;
    /* justify-content:  */
}

/* Components on side */
.component:hover {
    background-color: rgb(255, 225, 225);
    color: var(--plex-color);

    /* cursor: pointer; */
}

#plexusLogo {
    width: calc(1.1 * var(--normal-bullet-font-size));
    /* transform: rotate(33deg) */
}

/* MISC */
.button-container {
    /* float:left; */
    display: inline-block;
    white-space: nowrap;
    overflow-x: auto;
    /* top: 100px; */
    /* display: flex;
  justify-content: space-between; */
}
.newButton {
    margin: 0% 0.2em;
    /* color: var(--plex-color); */
    /* display: flex;
  justify-content: space-between; */
}
#top {
    display: flex;
    justify-content: space-between;
}
.button1 {
    position: relative;
    margin: auto;
    height: 30px;
    width: 80px;
    border-radius: 30%;
    cursor: pointer;
    border: 2px solid var(--plex-color);
}

/* Cytoscape Graph Visualization*/
#cy {
    /* height: 90%; */
    /* width: 100%; */
    z-index: 1;
    display: block;
}
#lasttwo {
    /* text-align: center; */
    /* display: inline-block;
  font-family: var(--the-font-family);
  font-style: italic;
  margin: 0% 2em;
  font-size: 24px; */
}
#addNodeButton {
    /* border-radius: 40% */
}
.potential {
    color: #00008b;
}

#above {
    /* display: flex;
  justify-content: left; */
    color: var(--plex-color);
    text-align: left;
}

/* Loading */
#loading {
    margin-top: 10%;
    /* height: 80%; */
    /* justify-content: sp; */
    align-items: center;
    display: flex;
    flex-direction: column;
    color: var(--plex-color);
    /* background-color: rgb(0, 9, 85);; */
    font-size: 7em;
    text-align: center;
    font-family: 'Optima', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    font-style: italic;
}
#loading > * {
    padding-top: 3vh;
}

/* Zoom */
#zoomIcon {
    height: 26px;
    width: 26px;
    display: inline-block;
    /* top: 10px; */
}
#zoomLevel {
    font-size: 28px;
    border-radius: 4px;
    display: inline-block;
    color: rgb(85, 133, 254);
    /* border: dotted; */
    /* border-width: 5px; */
}
#zoom {
    font-style: var(--font-weight);
    /* margin: 0px 1em; */
    /* float: left; */
    display: inline-block;
    font-family: 'Courier';
    position: relative;
    /* top: 5px; */
}

/* Saved snackbar */
#snackbar {
    visibility: hidden;
    min-width: 50px;
    /* margin-left: -400px; */
    color: var(--plex-color);
    background-color: white;
    text-align: center;
    border-radius: 10px;
    border: 1px solid var(--plex-color);
    padding: 16px;
    position: absolute;
    opacity: 0.9;
    z-index: 1;
    left: 20px;
    bottom: 30px;
    font-size: 36px;
    font-style: italic;
    font-family: 'Apple Chancery, cursive';
}
#snackbar.show {
    visibility: visible;
    -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
    from {
        bottom: 0;
        opacity: 0;
    }
    to {
        bottom: 30px;
        opacity: 0.9;
    }
}

@keyframes fadein {
    from {
        bottom: 0;
        opacity: 0;
    }
    to {
        bottom: 30px;
        opacity: 0.9;
    }
}

@-webkit-keyframes fadeout {
    from {
        bottom: 30px;
        opacity: 0.9;
    }
    to {
        bottom: 0;
        opacity: 0;
    }
}

@keyframes fadeout {
    from {
        bottom: 30px;
        opacity: 0.9;
    }
    to {
        bottom: 0;
        opacity: 0;
    }
}
@-webkit-keyframes jump {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
@keyframes jump {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.jumpie {
    -webkit-animation-duration: 1s;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-timing-function: linear;
}

/* SLIDER */
/* The slider itself */
.slider {
    -webkit-appearance: none; /* Override default CSS styles */
    appearance: none;
    width: 100%; /* Full-width */
    height: 25px; /* Specified height */
    background: #d3d3d3; /* Grey background */
    outline: none; /* Remove outline */
    opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
    -webkit-transition: 0.2s; /* 0.2 seconds transition on hover */
    transition: opacity 0.2s;
}

/* Mouse-over effects */
.slider:hover {
    opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 25px; /* Set a specific slider handle width */
    height: 25px; /* Slider handle height */
    background: #4caf50; /* Green background */
    cursor: pointer; /* Cursor on hover */
}

.slider::-moz-range-thumb {
    width: 25px; /* Set a specific slider handle width */
    height: 25px; /* Slider handle height */
    background: #4caf50; /* Green background */
    cursor: pointer; /* Cursor on hover */
}

.shortcuts-container {
    display: flex;
    flex-direction: row;
    margin: 0ex 0ex;
    margin-left: 1.2ex;
}
.shortcuts {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 2ex;
    overflow-y: auto;
}
.shortcut {
    padding: 0.4ex;
    font-size: calc(0.8 * var(--normal-bullet-font-size));
}
.shortcutItems {
}

/* MENTIONS UTILIZED IN Note.tsx */

.mentions {
    position: relative;
    /* margin: 1em 0; */
    width: 75%;
    width: fit-content;
    display: inline-block;
}

/* Elimated black border with one of these, but forgot which */
.mentions--singleLine .mentions__control {
    display: inline-block;
    width: 100%;
}
.mentions--singleLine .mentions__highlighter {
    padding: 1px;
    border: 2px inset transparent;
}
.mentions--singleLine .mentions__input {
    padding: 1px;
    /* padding: 2px; */
    border: 2px transparent inset;
    outline: none;
}

.mentions--multiLine .mentions__control {
    font-family: 'Optima';
    font-size: 14pt;
}
.mentions--multiLine .mentions__highlighter {
    border: 0px solid transparent;
    border-bottom: 2px solid;
    /* var(--medium-color); */
    padding: 9px;
    min-height: 63px;
}
.mentions--multiLine .mentions__input {
    border: 0px solid silver;
    border-bottom: 2px solid transparent;
    /* var(--medium-color); */
    padding: 9px;
    outline: 0;
}

.mentions__suggestions__list {
    background-color: white;
    /* border: 1px solid rgba(0, 0, 0, 0.15); */
    font-size: 10pt;
}

.mentions__suggestions__item {
    padding: 5px 15px;
    border-radius: 0.5em;
    width: fit-content;
    /* border-bottom: 1px solid rgba(0, 0, 0, 0.15); */
}

.mentions__suggestions__item--focused {
    background-color: #cee4e5;
}

.mentions__mention {
    z-index: -100;
    color: var(--high-color);
    text-decoration: underline !important;
    pointer-events: none;
}

.breadcrumbs {
    margin-right: 20px;
    margin-left: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.breadcrumb {
    margin: 5px;
}
.top-ribbon {
    padding: 0.8em;
    padding-left: 1.4em;
    padding-bottom: 0;
    position: relative;
    display: flex;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-self: flex-start;
    /* background-color: rgb(221, 221, 221); */
    /* box-shadow: var(--boxShadowSmall); */
    /* border-bottom: 1px rgb(236, 236, 236) solid; */
    /* margin: 0 4vw; */
}

.plexRow {
    display: flex;
    flex-direction: row;
}
.alignItemsCenter {
    align-items: center;
}
.alignItemsStart {
    align-items: flex-start;
}

.plexColumn {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* transition: position 1s; */
}
.plexColumn + .plexColumn {
}
.pages-container {
    display: flex;
    flex-direction: row;
    width: 100vw;
    /* width: 97vw; */
    height: 100%;
    align-items: center;
    flex: 1;
    /* justify-content: flex-start; */
    justify-content: space-between;
    align-self: center;
    margin: 0 6px;
}
.panel {
    padding: 1% 2.5%;
    flex: 2;
    /* padding: 0 1vw; */
    display: flex;
    flex-direction: column;
    /* justify-content: flex-start; */
    /* justify-self: center; */
    align-items: flex-start;
    height: max-content;
    height: 100%;
    max-width: 75em;
    min-width: 20pt;
    border-radius: 10pt;
    /* background-color: white; */
    /* margin: 0 30vw; */
    margin-top: 1vh;
    margin-bottom: 1vh;
    overflow-y: auto;
}

@media all and (max-width: 390pt) {
    .panel.conceptStoreOpen {
        display: none;
        flex: 0;
    }
    .pages-container {
        justify-content: center;
    }
    #concept-store-container {
        max-width: none !important;
    }
    .panel.secondary.conceptStoreOpen {
        display: none;
        flex: 0;
    }
}

.panel.secondary {
    flex: 1;
    max-width: 32vw;
    box-shadow: 0 0 4px gainsboro;

    /* background-color: rgb(250, 250, 250); */
}
.plexColumn:hover {
    cursor: default;
}
