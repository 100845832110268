:root {
    --boxShadowBig: 0px 0px 10px 0px var(--high-color);
    --boxShadowHuge: 0px 0px 20px 0px var(--plex-color);
    --boxShadowRegistering: 0px 0px 6px 2px rgb(231, 232, 255);
}
.emailSignupContainer {
    box-shadow: var(--boxShadowFull);
    border-radius: 0.3em;
    padding: 0.2em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: fit-content;
    align-items: center;
    margin: 1em 0;
    background-color: white;
    gap: 0.2em;
    flex-wrap: wrap;
}
/* .emailSignupContainer > * + .emailSignupContainer > * {
    padding-right: 1em;
} */

.emailSignupContainer:focus-within {
    box-shadow: var(--boxShadowRegistering);
    outline: rgb(215, 240, 255) 3px solid;
}
.emailSignupContainer > .form-control {
    color: black !important;
    border: none;
    box-shadow: none !important;
    font-size: 15px;
    padding-right: 0 !important;
    /* margin-right: 0.5em !important; */
    padding-left: 0.5em !important;
    outline: none !important;
}
.get-early-access-button {
    /* letter-spacing: 0.px; */
    font-size: 0.8em;
    background-color: rgb(255, 169, 176);
    /* var(--plex-color); */
    color: white;
    height: fit-content;
    width: fit-content;
    font-weight: bold;
    padding: 0.4em 0.6em;
    border-radius: 0.5em;
    box-shadow: var(--boxShadowFull);
    transition: box-shadow 0.2s;
    text-decoration: none;
    /* transition: padding 0.1s; */
}
.get-early-access-button.authorized {
    background-color: rgb(255, 110, 120);
}
.get-early-access-button:hover {
    cursor: pointer;
    box-shadow: var(--boxShadowBig);
}
.get-early-access-button:active {
    box-shadow: var(--boxShadowHuge);
}
.smallerButton {
    /* transition: padding 0.1s; */
}
.email-text-input {
    margin: 0 0.2em;
    outline: none !important;
    text-align: left;
    font-size: var(--normal-bullet-font-size);
    color: black;
    overflow: none;
    transition: width 0.2s;
}
.email-text-input:hover {
    cursor: text;
}
[contenteditable='true']:empty:before {
    content: attr(placeholder);
    /* display: block; */
    color: #aaa;
}
.email-text-input.authorized {
    /* color: rgb(202, 0, 17); */
    /* font-weight: bold; */
    /* text-decoration: underline gray 1px; */
}
