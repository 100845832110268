.headerButton {
    font-size: var(--normal-bullet-font-size);
    /* color: var(--bullet-color); */
    color: gray;
    height: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px 1.5vw;
    margin: 2pt 4pt;
    /* align-self: flex-start; */
    /* background-color: var(--very-light-gray); */
    border-radius: calc(0.6 * var(--group-border-radius));
    box-shadow: var(--boxShadowSmall);
    /* width: 3em; */
}
.headerButton:hover,
.headerButton.on {
    cursor: pointer;
    color: var(--font-color);
    background-color: var(--very-light-color);
    box-shadow: var(--boxShadowSmall);
}
.headerButton:active {
    box-shadow: var(--boxShadowHover);
}
.headerButton > * > input {
    width: 2em !important;
}
.headerButton.focused > * > input {
    width: 50vw !important;
}
input {
    transition: width 0.25s ease-in-out !important;
}
