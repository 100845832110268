.page-in-store-caret {
    margin-right: 0.1em !important;
    color: rgb(201, 198, 230);
    font-size: 1.3em !important;
    transition: transform 0.15s, color 0.2s;
    display: inline-flex;
    padding: 0 0.3em;
    font-size: medium;
}
.page-in-store-caret.ninety-degrees {
    color: rgb(227, 227, 227);
}
.page-tree-in-store {
    background-color: transparent;

    border-radius: 10px;
}
/* .page-tree-in-store.dragover {
    outline: 2px var(--light-purple) solid;
} */
