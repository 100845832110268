.references {
    /* margin-top: 1em; */
    color: rgb(103, 103, 103);
    /* font-size: 15px; */
    /* box-shadow: 0 0 5px lightgray; */
}
.references-title {
    /* font-weight: bold; */
    font-size: large;
    cursor: default;
    padding: 0.4em 0;
    /* margin-top: 5px; */
    cursor: pointer;
    /* color: rgb(176, 176, 176); */
}
.references-title.empty {
    color: gainsboro;
}
.reference-item {
    /* margin-left: 0.4em; */
    margin: 0.2em 0;
    line-height: 1.3;
    padding: 0.5em;
    border-radius: 0.3em;
    /* background-color: rgba(128, 128, 128, 0.057); */
    display: flex;
    /* border-left: 2px solid gainsboro; */
    /* font-size: 14px; */
}
.reference-item-title {
    color: var(--deep-blue);
    cursor: pointer;
    width: fit-content;
    border-radius: 5px;
    margin: 0.2em;
    /* font-size: 1.1em; */
}
.reference-item-title:hover {
    opacity: 1;

    color: var(--electric-purple);
    background-color: var(--background-purple);
}
.reference-content {
    /* margin-left: 0.4em; */
    /* padding: 0.6em; */
    border-radius: 8px;
}
