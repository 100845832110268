.settings {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: left;
    padding: 0px 10px 0px 10px;
}
.settingsTitle {
    padding: 7px 0px;
    align-self: center;
    font-size: 16pt;
}
.labelTag {
    font-size: 16px;
    font-weight: 400;
    padding: 4px;
}
.actions > * {
    margin: 0.3em;
}
.settingsLink {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: black;
    border-radius: 0.4em;
    background-color: rgb(238, 238, 238);
    font-size: 14px;
    padding: 0.5em;
    margin: 0.5em;
    text-decoration: none;
}
.settingsLink:hover {
    background-color: rgb(233, 233, 233);
    text-decoration: none;
    color: black;
}
.settingsLink:hover {
    cursor: pointer;
}
