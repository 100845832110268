div {
    border-radius: 0.5em;
}
.paste-button {
    color: rgb(187, 187, 187);
    margin: 0 0.5em;
    /* padding: 0.1em; */
    /* transform: rotate(-90deg); */
}

.paste-button:active {
    background-color: rgba(128, 128, 128, 0.153);
    color: var(--electric-purple);
}
