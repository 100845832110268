.pinned {
    /* background-color: rgb(244, 244, 244); */
    /* padding: 0.3em;
    margin: 0.6em; */
    /* background-color: transparent; */
    box-shadow: 0 0 2px rgb(191, 191, 191);
    margin: 1em;
}
.pinned-note-title {
    font-size: 0.9em;
    color: gray;
    align-self: left;
}
