.editor-container {
    max-width: 600pt;
    background-color: white;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* box-shadow: 0 0 5px rgb(232, 232, 232); */
    height: 100%;
    width: 100%;
    font-size: var(--normal-bullet-font-size);
    /* outline: 2px gainsboro solid; */
}
.note-title {
}

.linked-title {
    color: var(--deep-blue);
    /* color: var(--dark-gray); */
}
