/* for the right sidebar */

.sidebar-arrow {
    /* border-bottom: var(--sidebar-border); */
    /* border-top: var(--sidebar-border); */
    background-color: rgb(238, 237, 237);
    /* width: 21px; */
    align-self: center;
    /* margin: 32vh 0; */
    /* height: 20%; */
}

.sidebar-arrow:hover {
    background: var(--background-color);
    cursor: pointer;
}
.right-sidebar-arrow {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    /* border-left: var(--sidebar-border); */
}
.left-sidebar-arrow {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    height: fit-content;
    /* border-right: var(--sidebar-border); */
}
.sidebar-general {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    display: flex;
    flex-direction: column;
    /* width: 17.5%; */
    border-radius: 8px;
    margin-top: 8vh;
    margin-bottom: 8vh;
    background-color: rgba(250, 250, 250, 0.897);
    font-size: 11pt;
    box-shadow: var(--boxShadowFull);
    /* border-top: var(--sidebar-border); */
    /* border-bottom: var(--sidebar-border); */
}
.sidebar-general > .div {
    flex: 1;
}

.plexus-logo {
    /* width: fit-content !important;
    padding: 0.4em;
    font-size: 0.7em; */
}
#concepts-tab {
    width: fit-content !important;
    padding: 0.4em;
    font-size: 0.6em;
}
