#seeSettingsIcon {
    transition: transform 0.5s ease-in-out;
}
#seeSettingsButton.on > #seeSettingsIcon {
    transform: rotate(100deg);
}
#seeSettingsButton {
    margin: 1vh;
    position: absolute;
    right: 0;
    top: 0;
}
