.references-from-a-page {
    /* color: red; */
    margin: 0.3em 0 0em;
    padding: 0.3em;
    border-radius: 0.5em;
    background-color: rgb(244, 244, 244);
    /* outline: 2px rgb(239, 239, 255) solid; */
    cursor: pointer;
}
.references-from-a-page.clear {
    /* background-color: transparent; */
}

.toggle-and-title {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.referencesCaret {
    margin: 0 0.1em;
}
